import { OsConnection } from './osConnection.interface';
import { PrintableType } from '../print/printableType.enum';
import { PrinterUtils } from '../print/printer.utils';
import { CustomerDisplayLine } from '../customer-display/customerDisplayLine.model';
import { CustomerDisplayMessage } from '../customer-display/customerDisplayMessage.model';

class EpsonCustomerDisplay {
  private window = window as any;
  private line1: CustomerDisplayLine;
  private line2: CustomerDisplayLine;

  constructor(
    line1: CustomerDisplayLine = new CustomerDisplayLine('', 0),
    line2: CustomerDisplayLine = new CustomerDisplayLine('', 0)
  ) {
    this.line1 = line1;
    this.line2 = line2;
  }

  display() {
    if (
      this.window.external &&
      typeof this.window.external.mostrarEnVisor === 'function'
    ) {
      this.window.external.mostrarEnVisor(
        this.line1.getText(),
        this.line1.getInitialPosition(),
        this.line2.getText(),
        this.line2.getInitialPosition(),
        'EPSON_DM_Dx'
      );
    }
  }
}

export class AspNetConnection implements OsConnection {
  window = window as any;
  private aspNetType: string;

  customerDisplay(message: CustomerDisplayMessage) {
    const line1 = message.lineStack[0];
    const line2 = message.lineStack[1];
    new EpsonCustomerDisplay(line1, line2).display();
  }

  print(document: string, printableType: PrintableType) {
    const documentAsByteArray = new PrinterUtils().pdfToBinary(document);
    this.aspNetType = this.translateToType(printableType);
    if (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    ) {
      this.window.external.imprimirPDFBinario(documentAsByteArray, 'documento');
    }
  }

  ventaTarjeta(
    idTpv: string,
    NumDecimalesDivisa: string,
    ContadorAlbaranesVenta: string,
    ContadorAlbaranesDevolucion: string,
    Identificador_Alb: string,
    OperacionVenta: string,
    TipoComunicacionVenta: string,
    ImporteVenta: string,
    ImporteVenta_dev: string,
    TpvIdOrigen: string,
    NumTarjetaOrigen: string,
    CaducidadTarjetaOrigen: string,
    NumOperacionOrigen: string,
    IdRtsOrigen: string,
    NumTerminal: string,
    VersionWeb: string,
    NumCom: string,
    ClaveCom: string,
    COMPinPad: string,
    TimeOutTjta: string = '0'
  ) {
    let res =
      'OK:<Operaciones version="6.0"><resultadoOperacion><importe>0.01</importe><moneda>978</moneda><tarjetaClienteRecibo>************1535' +
      '</tarjetaClienteRecibo><tarjetaComercioRecibo>************1535</tarjetaComercioRecibo><marcaTarjeta>1</marcaTarjeta><caducidad>0000' +
      '</caducidad><comercio>266490085</comercio><terminal>23</terminal><pedido></pedido><tipoTasaAplicada>DEB</tipoTasaAplicada>' +
      '<identificadorRTS>070014191003172220428687</identificadorRTS><factura>70579930003</factura><fechaOperacion>2019-10-03 17,22,18.0</fechaOperacion>' +
      '<estado>F</estado><resultado>OPERACION ACEPTADA</resultado><codigoRespuesta>079642</codigoRespuesta>' +
      '<firma>B1068DD4D3B0716B607263C1ECD503ED7522F34A24BC0DB4C1E24B8BD579B816</firma><operacionemv>true</operacionemv>' +
      '<resverificacion>0000000000</resverificacion><conttrans>000062</conttrans><sectarjeta>01</sectarjeta><idapp>A0000000031010</idapp>' +
      '<DDFName>A0000000031010</DDFName><etiquetaApp>VISA CLASICA</etiquetaApp><operContactLess>TRUE</operContactLess><ReciboSoloCliente>TRUE</ReciboSoloCliente>' +
      '</resultadoOperacion></Operaciones>';
    if (
      this.window.external &&
      typeof this.window.external.ventaTarjeta === 'function'
    ) {
      res = this.window.external.ventaTarjeta(
        idTpv,
        NumDecimalesDivisa,
        ContadorAlbaranesVenta,
        ContadorAlbaranesDevolucion,
        Identificador_Alb,
        OperacionVenta,
        TipoComunicacionVenta,
        ImporteVenta,
        ImporteVenta_dev,
        TpvIdOrigen,
        NumTarjetaOrigen,
        CaducidadTarjetaOrigen,
        NumOperacionOrigen,
        IdRtsOrigen,
        NumTerminal,
        VersionWeb,
        NumCom,
        ClaveCom,
        COMPinPad,
        TimeOutTjta
      );
    }
    return res;
  }

  private translateToType(printableType: PrintableType): string {
    switch (printableType) {
      case PrintableType.ALBARAN:
        return 'justificante';
      case PrintableType.LABEL:
        return 'etiqueta';
      case PrintableType.TICKET:
        return 'ticket';
      case PrintableType.CASH_CLOSURE_REPORT:
        return 'documento';
      case PrintableType.CASH_OPENING_REPORT:
        return 'documento';
    }
  }
}
