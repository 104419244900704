import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { VentaService } from '../../services/venta.service';
import { Carrito } from '../../models/carrito.model';
import { Entrada } from '../../models/entrada.model';
import { NumpadService } from '../../../../shared/services/numpad.service';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { Router, NavigationEnd } from '@angular/router';
import { OpcionesImpresionQR, PagoService } from '../../services/pago.service';
import * as moment from 'moment';
import * as xml2js from 'xml2js';
import 'moment/min/locales';
import { StepperNavigationService } from '../../services/stepper-navigation.service';
import { ConfigurationService } from 'src/app/core/conf/configuration.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';

import { PrintService } from 'src/app/shared/services/print.service';
import {
  flatMap,
  map,
  concatMap,
  mergeMap,
  take,
  catchError,
  first,
  switchMap,
  takeWhile,
} from 'rxjs/operators';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import { EntradasService } from '../../services/entradas.service';
import { MotivosService } from '../../services/motivos.service';
import { ToastrService } from 'ngx-toastr';
import { SidebarNavigationService } from 'src/app/shared/services/sidebar-navigation.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ButacasService } from 'src/app/modules/auditorio/services/butacas.service';
import { entradaToButaca } from 'src/app/modules/auditorio/components/seleccion-butacas/butacaToEntrada.mapper';
import { CardPaymentFactory } from 'src/app/core/hardware/card-payment/cardPayment.factory';
import { CardPaymentType } from 'src/app/core/hardware/card-payment/cardPaymentType.enum';
import { VisorService } from 'src/app/shared/services/visor.service';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { InfoToast } from 'src/app/core/toast/info.toast';
import { Subscription, forkJoin, of, throwError } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { FacturaService } from 'src/app/shared/services/factura.service';
import { LocalizadorService } from 'src/app/modules/localizador/services/localizador.service';
import { ProcedenciaService } from '../../services/procedencia.service';
import {
  extractErrorCode,
  extractErrorMessage,
} from 'src/app/utils/error-handler/xml-parser';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { pais } from '../../models/procedencias.model';
import { SuccessToast } from 'src/app/core/toast/success.toast';

interface DatosAlbaranPagoTarjeta {
  Identificador: string;
  NumAlbaran: string;
  TpvId: string;
}

interface DatosPagoVisa {
  DDFName?: string;
  ReciboSoloCliente?: string;
  caducidad?: string;
  codigoRespuesta?: string;
  comercio?: string;
  conttrans?: string;
  estado?: string;
  etiquetaApp?: string;
  factura?: string;
  fechaOperacion?: string;
  firma?: string;
  idapp?: string;
  identificadorRTS?: string;
  importe?: string;
  marcaTarjeta?: string;
  moneda?: string;
  operContactless?: string;
  operacionemv?: string;
  pedido?: string;
  resultado?: string;
  resverificacion?: string;
  sectarjeta?: string;
  tarjetaClienteRecibo?: string;
  tarjetaComercioRecibo?: string;
  terminal?: string;
  tipoPago?: string;
  tipoTasaAplicada?: string;
}

@Component({
  selector: 'ticketing-resumen-compra',
  templateUrl: './resumen-compra.component.html',
  styleUrls: ['./resumen-compra.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'col-resumen',
  },
})
export class ResumenCompraComponent implements OnInit, OnDestroy {
  config = PERFECT_SCROLLBAR_CONFIG;
  carrito: Carrito;
  ventanaActualDePago;
  ventaActualNoEntradas;
  sePuede = true;
  idioma = 'es-ES';
  paseHorarioSeleccion = 'SIN SELECCIÓN DE PASE HORARIO';
  divisaSimbSeparacionMiles: string;
  divisaSimboloDecimal: string;
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  esGranDivisa: boolean;
  divisaSimboloDerecha: string;
  estaPagando = false;
  routerSub: Subscription;
  carritoSub: Subscription;
  datosInicioSub: Subscription;
  marcarDesmarcarSub: Subscription;
  pagarTarjetaSub: Subscription;
  pagarSub: Subscription;
  cabeceraSub: Subscription;
  carritoOrigen: Carrito;
  carritoOrigenSub: Subscription;
  hayDescuento = true;
  hayDescuentoSub: Subscription;
  validarTarjetaMonederoSub: Subscription;
  reImprimirTicketsSub: Subscription;
  imprimiendoTickets: boolean = false;
  disabledEntrar = false;
  listadoPaises: any[];
  private alive = true;
  ticketsProductosArr: any[];
  @Input() masEntradas;
  constructor(
    public ventaService: VentaService,
    private numpadService: NumpadService,
    private pagoService: PagoService,
    private stepper: StepperNavigationService,
    private albaranService: AlbaranService,
    private configuration: ConfigurationService,
    private formControlService: FormControlService,
    public router: Router,
    private stepperNavigation: StepperNavigationService,
    private sidebarNavigation: SidebarNavigationService,
    private modalService: ModalService,
    private printService: PrintService,
    private entradaService: EntradasService,
    private motivosService: MotivosService,
    private toast: ToastrService,
    private butacaService: ButacasService,
    private visor: VisorService,
    private facturaService: FacturaService,
    private configuracion: AppConfiguration,
    private localizadorService: LocalizadorService,
    private procedenciaService: ProcedenciaService,
    private translateService: TranslateService,
    private toastr: ToastrService
  ) {
    this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.ventanaActualDePago =
          val.url.split('/')[2] === 'pago' ? true : false;
        /*         this.ventanaActualDePago =
          val.url.split('/')[2] === 'horarios' ? false : true; */
        this.ventaActualNoEntradas =
          val.url.split('/')[2] === 'entradas' ? false : true;
      }
    });
  }

  ngOnInit() {
    this.divisaSimbSeparacionMiles =
      this.configuration.divisasValue[0].SimbSeparacionMiles;
    this.divisaSimboloDecimal =
      this.configuration.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configuration.divisasValue[0].simbolo;
    this.divisaDecimales = Number(
      this.configuration.divisasValue[0].NumeroDecimales
    );
    this.divisaPosicion = this.configuration.divisasValue[0].PosicionDivisa;
    this.divisaSimboloDerecha =
      this.configuration.divisasValue[0].SimboloDerecha;

    this.carritoSub = this.ventaService.carrito.subscribe((carrito) => {
      this.carrito = carrito;
    });
    this.carritoOrigenSub = this.ventaService.carritoOrigen.subscribe(
      (carrito2) => {
        this.carritoOrigen = carrito2;
      }
    );
    this.datosInicioSub = this.configuration.datosInicio.subscribe((conf) => {
      this.idioma = conf.Idioma;
    });
    this.hayDescuentoSub = this.ventaService.hayDescuento$.subscribe((desc) => {
      this.hayDescuento = desc;
    });
    this.facturaService
      .getPaises()
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: any) => {
        // ;
        if (res.DatosPaises) {
          // seleccionable
          this.listadoPaises = res.DatosPaises;
          // 
        }
      });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.carritoSub) {
      this.carritoSub.unsubscribe();
    }
    if (this.datosInicioSub) {
      this.datosInicioSub.unsubscribe();
    }
    if (this.marcarDesmarcarSub) {
      this.marcarDesmarcarSub.unsubscribe();
    }
    if (this.carritoOrigenSub) {
      this.carritoOrigenSub.unsubscribe();
    }
    if (this.hayDescuentoSub) {
      this.hayDescuentoSub.unsubscribe();
    }
    if (this.validarTarjetaMonederoSub) {
      this.validarTarjetaMonederoSub.unsubscribe();
    }
    if (this.reImprimirTicketsSub) {
      this.reImprimirTicketsSub.unsubscribe();
    }

    // if (this.pagarTarjetaSub) {
    //   this.pagarTarjetaSub.unsubscribe();
    // }
    //  if (this.pagarSub) {
    //   this.pagarSub.unsubscribe();
    // }
    // if (this.cabeceraSub) {
    //   this.cabeceraSub.unsubscribe();
    // }
  }

  redondearPrecios(precio) {
    return Math.round(precio * 100) / 100;
  }

  eliminarLinea(linea: { entrada: any; cantidad: number }) {
    if (linea.entrada.noEditable) {
      return;
    }
    if (this.ventanaActualDePago) {
      return;
    }
    //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables
    if (
      linea.entrada.GrupoMotivoId &&
      Number.parseInt(linea.entrada.GrupoMotivoId, 10) > 0
    ) {
      this.entradaService.setEntradaSeleccionada(linea.entrada);
      this.modalService.open('selector-motivos');
      return;
    }

    // if (linea.entrada.TipoProducto == '2') {
    //   let motivoId = 0;
    //   let tipoEntradaId; // Yaribel 20201229  Añadimos variable para capturar tipo de entrada
    //   linea.entrada.PromocionEntradas.forEach(element => {
    //     if (element.GrupoMotivoId && Number.parseInt(element.GrupoMotivoId , 10) > 0) {
    //       motivoId = element.GrupoMotivoId;
    //       tipoEntradaId = element.TipoEntradaId; // Yaribel 20201229  Añadimos variable para capturar tipo de entrada
    //     }
    //   });
    //   if (motivoId > 0) {
    //     this.entradaService.setEntradaSeleccionada(linea.entrada);
    //     //<< Yaribel 20201229  controlamos la llamada al modal solo si los motivos son seleccionables
    //   this.entradaService.comprobarGrupoMotivoDescuentoSeleccionable(tipoEntradaId).subscribe(motivoSeleccionable =>{
    //     if(motivoSeleccionable)
    //     {
    //       this.modalService.open('selector-motivos');
    //       return;
    //     }
    //   });
    //   // Yaribel 20201229  controlamos la llamada al modal solo si los motivos son seleccionables        >>
    //   }
    // }  else if (linea.entrada.GrupoMotivoId && Number.parseInt(linea.entrada.GrupoMotivoId , 10) > 0) {
    //   this.entradaService.setEntradaSeleccionada(linea.entrada);
    //   //<< Yaribel 20201229  controlamos la llamada al modal solo si los motivos son seleccionables
    //   this.entradaService.comprobarGrupoMotivoDescuentoSeleccionable(linea.entrada.TipoEntradaId).subscribe(motivoSeleccionable =>{
    //     if(motivoSeleccionable)
    //     {
    //       this.modalService.open('selector-motivos');
    //       return;
    //     }
    //   });
    //   // Yaribel 20201229  controlamos la llamada al modal solo si los motivos son seleccionables        >>
    // }

    // Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables >>

    this.carrito.entradas = this.carrito.entradas.filter((e) => e !== linea);

    this.carrito.recalcularTotales();
    this.ventaService.setCarrito(this.carrito);
    if (!linea.entrada.auditorio) {
      const input = document.getElementById(
        // `linea-${this.masEntradas}${this.numpadService.entrada.idTarifa}`
        `linea-${this.ventaService.masEntradasValue}${this.numpadService.entrada.idTarifa}`
      );
      if (this.ventaService.carritoValue.horarios.length > 0) {
        const indicesEliminar = [];
        const recintosEliminar = [];

        linea.entrada.recintos.forEach((r) => recintosEliminar.push(r.Recinto));
        //
        this.ventaService.carritoValue.horarios.forEach((item, index) => {
          //
          if (recintosEliminar.includes(item.recinto.Recinto)) {
            //
            //
            if (item.cantidadxentrada && item.cantidadxentrada.length > 0) {
              if (
                !linea.entrada.Caracteristicas ||
                linea.entrada.Caracteristicas.NumPeriodos == ''
              ) {
                if (item.cantidad - linea.cantidad > 0) {
                  item.cantidad = item.cantidad - linea.cantidad;
                  item.resumen = `${item.recinto.NombreRecinto} - ${moment(
                    item.recinto.fecha,
                    'YYYY/MM/DD'
                  )
                    .locale(this.idioma)
                    .format('L')} ${item.sesion.HoraInicio} (${item.cantidad})`;
                  // this.ventaService
                  // .reservaAforo(item.sesion.RecintosSesionId, item.cantidad, linea.entrada.TipoEntradaId)
                  // .subscribe();
                  this.ventaService
                    .reservaAforoProductos(
                      this.mapeaListaentradas2ListaProductos(
                        item.cantidadxentrada
                      ),
                      item.recinto.Recinto,
                      this.fechaYYYYMMDD2slash(
                        item.sesion.Fecha,
                        item.sesion.HoraInicio
                      )
                    )
                    .subscribe();
                } else {
                  this.ventaService
                    .eliminarAforoSesion(item.sesion.RecintosSesionId)
                    .subscribe((data) => {});
                  indicesEliminar.push(index);
                }
              } else {
                if (item.cantidadxentrada.length > 0) {
                  //for (const entradaR of item.cantidadxentrada) {
                  //if (entradaR.entrada.TipoEntradaId ==  linea.entrada.TipoEntradaId) {
                  this.ventaService
                    //.reservaAforo(item.sesion.RecintosSesionId, item.cantidad - linea.cantidad, linea.entrada.TipoEntradaId)
                    .reservaAforoProductos(
                      this.mapeaListaentradas2ListaProductos(
                        item.cantidadxentrada,
                        linea.entrada.TipoEntradaId
                      ),
                      item.recinto.Recinto,
                      this.fechaYYYYMMDD2slash(
                        item.sesion.Fecha,
                        item.sesion.HoraInicio
                      )
                    )
                    .subscribe();
                  //}
                  //}
                }
              }
            } else {
              if (item.cantidad - linea.cantidad > 0) {
                item.cantidad = item.cantidad - linea.cantidad;
                item.resumen = `${item.recinto.NombreRecinto} - ${moment(
                  item.recinto.fecha,
                  'YYYY/MM/DD'
                )
                  .locale(this.idioma)
                  .format('L')} ${item.sesion.HoraInicio} (${item.cantidad})`;
                // <<Yaribel 20210112 cambiamos peticion para que llame a reservaAforoProductos

                // //JS 20200811 Este lo dejamos porque no tiene productos
                // this.ventaService
                // .reservaAforo(item.sesion.RecintosSesionId, item.cantidad - 1)
                // .subscribe();
                this.ventaService
                  .reservaAforoProductos(
                    this.mapeaListaentradas2ListaProductos(
                      item.getTotalCantidadXEntradasSinPeriodos
                    ),
                    item.recinto.Recinto,
                    this.fechaYYYYMMDD2slash(
                      item.sesion.Fecha,
                      item.sesion.HoraInicio
                    )
                  )
                  .subscribe();
                // Yaribel 20210112 >>
              } else {
                this.ventaService
                  .eliminarAforoSesion(item.sesion.RecintosSesionId)
                  .subscribe((data) => {});
                indicesEliminar.push(index);
              }
            }
          }
        });
        if (indicesEliminar.length > 0) {
          indicesEliminar.forEach((indice) => {
            if (indice > 0) {
              indice--;
            }
            this.ventaService.carritoValue.horarios.splice(indice, 1);
          });
          //
        }
      }
      const oldVals = {
        selected: this.numpadService.selected,
        entrada: this.numpadService.entrada,
        input: document.getElementById(
          // `linea-${this.masEntradas}${this.numpadService.entrada.idTarifa}`
          `linea-${this.ventaService.masEntradasValue}${this.numpadService.entrada.idTarifa}`
        ),
      };
      this.numpadService.selected = input;
      this.numpadService.entrada = linea.entrada;
      this.formControlService.setCurrentFormGroup(
        null,
        input,
        input ? input.getAttribute('category') : ''
      );
      if (!input) {
        this.formControlService.setToBlank();
      }
      this.numpadService.selected = oldVals.selected;
      this.numpadService.entrada = oldVals.entrada;
      this.formControlService.setCurrentFormGroup(
        null,
        oldVals.input,
        oldVals.input ? oldVals.input.getAttribute('category') : ''
      );
      // this.numpadService.entrada = entradaOriginal;
      if (this.ventaActualNoEntradas) {
        this.router.navigate(['']);
        this.stepper.resetearPasos();
        this.stepperNavigation.continuarVenta.next(false);
      }
      // this.stepper.setNextSteps();
    } else {
      const b = entradaToButaca(linea.entrada);
      if (linea.entrada.extraData.tag === '') {
        this.marcarDesmarcarSub = this.butacaService
          .httpPostMarcarDesmarcarButaca(
            b,
            false,
            linea.entrada.extraData.idRecintoButaca
          )
          .subscribe(() => {
            //
            this.butacaService.removeButaca(b);
          });
      } else {
        this.marcarDesmarcarSub = this.butacaService
          .httpPostMarcarDesmarcarButaca(b, false)
          .subscribe(() => {
            //
            this.butacaService.removeButaca(b);
          });
      }
    }
  }

  clickToOpenMotivosModal(entrada) {
    if (entrada.noEditable || this.ventanaActualDePago) {
      return;
    }
    if (entrada.noEditable || this.ventaActualNoEntradas) {
      // TODO: modificacion por incidencia

      return;
    }
    if (this.ventaActualNoEntradas) {
      this.router.navigate(['']);
      this.stepper.resetearPasos();
      this.stepperNavigation.continuarVenta.next(false);
    }
    //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables
    if (
      entrada.GrupoMotivoId &&
      Number.parseInt(entrada.GrupoMotivoId, 10) > 0
    ) {
      this.entradaService.setEntradaSeleccionada(entrada);
      this.modalService.open('selector-motivos');
      return;
    }
  }

  focusValue(event: any, entrada) {
    if (entrada.noEditable) {
      return;
    }
    if (this.ventaActualNoEntradas) {
      this.router.navigate(['']);
      this.stepper.resetearPasos();
      this.stepperNavigation.continuarVenta.next(false);
    }
    if (
      entrada.GrupoMotivoId &&
      Number.parseInt(entrada.GrupoMotivoId, 10) > 0
    ) {
      this.entradaService.setEntradaSeleccionada(entrada);
      this.modalService.open('selector-motivos');
      return;
    }
    event.srcElement.value = '';
    this.numpadService.selected = event.srcElement;
    this.numpadService.entrada = entrada;
    this.formControlService.setCurrentFormGroup(
      null,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
    if (event.srcElement.getAttribute('category') === 'CANTIDAD') {
      this.numpadService.commaDisabled = true;
    } else {
      this.numpadService.commaDisabled = false;
    }
  }

  unfocusValue(event: any) {
    if (event.srcElement.value === '') {
      this.numpadService.setNumpadOrder('reset');
      return;
    }
  }

  getMotivosByEntradaId(id) {
    return this.motivosService.getDistinctCountMotivosByEntradaId(id);
  }

  private esPagoConTarjeta() {
    return (
      this.pagoService.pagoDesglosadoValue.pinpad +
        this.pagoService.pagoDesglosadoValue.datafono !=
      0
    );
  }

  private pagarConTarjeta() {
    let _pago = this.pagoService;
    this.pagarTarjetaSub = this.pagoService
      .generarPedidoTarjeta()
      .subscribe((datosAlbaran: DatosAlbaranPagoTarjeta) => {
        //<<Yaribel 20210820 controlamos si el pago es por pinpad que conecte, sino que sea virtual VSTS 8409
        const cardPaymentConnection = CardPaymentFactory.getCardPayment(
          this.pagoService.pagoDesglosadoValue.pinpad != 0
            ? CardPaymentType.PINPAD
            : CardPaymentType.DATAFONO
        );
        // >> VSTS 8409
        const infoTarjeta =
          this.configuration.deviceConfiguration.AppConfigJsonTarjeta;
        try {
          const respuestaPagoVisa = cardPaymentConnection.pay(
            this.pad(datosAlbaran.TpvId, 4),
            '2',
            datosAlbaran.NumAlbaran,
            '0',
            datosAlbaran.Identificador,
            'N',
            infoTarjeta.TarjetaTipoComunicacion,
            //WOIT en pagos multiples debe coger solo el pago con tarjeta
            (
              '' +
              (_pago.pagoDesglosadoValue.pinpad +
                _pago.pagoDesglosadoValue.datafono)
            ).replace('.', ','),
            //('' + this.ventaService.carritoValue.precioTotal).replace('.', ','),
            '0',
            '',
            '',
            '',
            '',
            '',
            infoTarjeta.TarjetaMCPTerminal,
            infoTarjeta.TarjetaMCPVersion,
            infoTarjeta.TarjetaMCPComercio,
            infoTarjeta.TarjetaMCPClave,
            infoTarjeta.TarjetaMCPPuertoCOM
          );
          const [tipoRespuesta, ...rest] = respuestaPagoVisa.split(':');
          const respuestaXml = rest.join();
          const that = this;
          if (tipoRespuesta === 'OK') {
            // that.respuesta1 = 'OK';
            // that.respuesta2 = respuestaXml;
            xml2js.parseString(respuestaXml, function (err, result) {
              if (err !== null) {
                that.toast.error(`[${err}`, 'Error', {
                  toastComponent: ErrorToast,
                });
                this.disabledEntrar = false;
                that.estaPagando = false;
                return;
              }
              const obj: DatosPagoVisa = {
                DDFName: result.Operaciones.resultadoOperacion[0].DDFName
                  ? result.Operaciones.resultadoOperacion[0].tipoPago
                  : undefined,
                ReciboSoloCliente: result.Operaciones.resultadoOperacion[0]
                  .ReciboSoloCliente
                  ? result.Operaciones.resultadoOperacion[0]
                      .ReciboSoloCliente[0]
                  : undefined,
                caducidad: result.Operaciones.resultadoOperacion[0].caducidad
                  ? result.Operaciones.resultadoOperacion[0].caducidad[0]
                  : undefined,
                codigoRespuesta: result.Operaciones.resultadoOperacion[0]
                  .codigoRespuesta
                  ? result.Operaciones.resultadoOperacion[0].codigoRespuesta[0]
                  : undefined,
                comercio: result.Operaciones.resultadoOperacion[0].comercio
                  ? result.Operaciones.resultadoOperacion[0].comercio[0]
                  : undefined,
                conttrans: result.Operaciones.resultadoOperacion[0].conttrans
                  ? result.Operaciones.resultadoOperacion[0].conttrans[0]
                  : undefined,
                estado: result.Operaciones.resultadoOperacion[0].estado
                  ? result.Operaciones.resultadoOperacion[0].estado[0]
                  : undefined,
                etiquetaApp: result.Operaciones.resultadoOperacion[0]
                  .etiquetaApp
                  ? result.Operaciones.resultadoOperacion[0].etiquetaApp[0]
                  : undefined,
                factura: result.Operaciones.resultadoOperacion[0].factura
                  ? result.Operaciones.resultadoOperacion[0].factura[0]
                  : undefined,
                fechaOperacion: result.Operaciones.resultadoOperacion[0]
                  .fechaOperacion
                  ? result.Operaciones.resultadoOperacion[0].fechaOperacion[0]
                  : undefined,
                firma: result.Operaciones.resultadoOperacion[0].firma
                  ? result.Operaciones.resultadoOperacion[0].firma
                  : undefined,
                idapp: result.Operaciones.resultadoOperacion[0].idapp
                  ? result.Operaciones.resultadoOperacion[0].idapp[0]
                  : undefined,
                identificadorRTS: result.Operaciones.resultadoOperacion[0]
                  .identificadorRTS
                  ? result.Operaciones.resultadoOperacion[0].identificadorRTS[0]
                  : undefined,
                importe: result.Operaciones.resultadoOperacion[0].importe
                  ? result.Operaciones.resultadoOperacion[0].importe
                  : undefined,
                marcaTarjeta: result.Operaciones.resultadoOperacion[0]
                  .marcaTarjeta
                  ? result.Operaciones.resultadoOperacion[0].marcaTarjeta[0]
                  : undefined,
                moneda: result.Operaciones.resultadoOperacion[0].moneda
                  ? result.Operaciones.resultadoOperacion[0].moneda[0]
                  : undefined,
                operContactless: result.Operaciones.resultadoOperacion[0]
                  .operContactLess
                  ? result.Operaciones.resultadoOperacion[0].operContactLess[0]
                  : undefined,
                operacionemv: result.Operaciones.resultadoOperacion[0]
                  .operacionemv
                  ? result.Operaciones.resultadoOperacion[0].operacionemv[0]
                  : undefined,
                pedido: result.Operaciones.resultadoOperacion[0].pedido
                  ? result.Operaciones.resultadoOperacion[0].pedido[0]
                  : undefined,
                resultado: result.Operaciones.resultadoOperacion[0].resultado
                  ? result.Operaciones.resultadoOperacion[0].resultado[0]
                  : undefined,
                resverificacion: result.Operaciones.resultadoOperacion[0]
                  .resverificacion
                  ? result.Operaciones.resultadoOperacion[0].resverificacion[0]
                  : undefined,
                sectarjeta: result.Operaciones.resultadoOperacion[0].sectarjeta
                  ? result.Operaciones.resultadoOperacion[0].sectarjeta[0]
                  : undefined,
                tarjetaClienteRecibo: result.Operaciones.resultadoOperacion[0]
                  .tarjetaClienteRecibo
                  ? result.Operaciones.resultadoOperacion[0]
                      .tarjetaClienteRecibo[0]
                  : undefined,
                tarjetaComercioRecibo: result.Operaciones.resultadoOperacion[0]
                  .tarjetaComercioRecibo
                  ? result.Operaciones.resultadoOperacion[0]
                      .tarjetaComercioRecibo[0]
                  : undefined,
                terminal: result.Operaciones.resultadoOperacion[0].terminal
                  ? result.Operaciones.resultadoOperacion[0].terminal[0]
                  : undefined,
                tipoPago: result.Operaciones.resultadoOperacion[0].tipoPago
                  ? result.Operaciones.resultadoOperacion[0].tipoPago
                  : undefined,
                tipoTasaAplicada: result.Operaciones.resultadoOperacion[0]
                  .tipoTasaAplicada
                  ? result.Operaciones.resultadoOperacion[0].tipoTasaAplicada[0]
                  : undefined,
              };
              that.pagoService.setOperacionesTarjetaValue(obj);
              that.llamarPeticionDePago();
            });
          }
          if (tipoRespuesta === 'KO') {
            xml2js.parseString(respuestaXml, (err, result) => {
              if (err) {
                that.toast.error(`[${err}`, 'Error KO', {
                  toastComponent: ErrorToast,
                  timeOut: 5000,
                });
                this.disabledEntrar = false;
                that.estaPagando = false;
                return;
              }
              that.toast.error(
                `[${result.Error[0].codigo[0]}] ${result.Error[0].mensaje[0]}`,
                'Error',
                { toastComponent: ErrorToast, timeOut: 5000 }
              );
              this.disabledEntrar = false;
              that.estaPagando = false;
              return;
            });
          }
          if (tipoRespuesta === 'ERR') {
            const errorCodigo = extractErrorCode(respuestaPagoVisa);
            let errorMessage = this.translateService.instant(errorCodigo);
            if (errorCodigo === 'UNKNOWN') {
              errorMessage = extractErrorMessage(respuestaPagoVisa);
            }
            that.toast.error(errorMessage, 'Error ERR', {
              toastComponent: ErrorToast,
              timeOut: 5000,
            });
            this.estaPagando = false;
            this.disabledEntrar = false;
            return;
          }
        } catch (e) {
          this.toast.error('Ocurrió un error inesperado', 'Error', {
            toastComponent: ErrorToast,
            timeOut: 5000,
          });
          this.estaPagando = false;
          this.disabledEntrar = false;
          return;
        }
      });
  }

  pagar() {
    this.visor.mostrarCambioCompra(this.pagoService.cambioValue);
    this.disabledEntrar = true;
    if (this.esPagoConTarjeta()) {
      this.pagarConTarjeta();
      return;
    }
    this.llamarPeticionDePago();
  }

  async anularVenta() {
    this.albaranService.anularDeshabilitado = true;
    let oldNumAlbaran: string = this.albaranService.albaranValue.NumAlbaran;
    if (this.albaranService.albaranValue.TienePagoCC === '1') {
      this.albaranService
        .generarIdentificadores(
          this.albaranService.albaranValue.TPVId,
          this.albaranService.albaranValue.Prefijo
        )
        .subscribe((data: any) => {
          this.albaranService
            .anularAlbaranTarjeta(
              data.DatosResult.Identificador,
              data.DatosResult.NumAlbaran
            )
            .subscribe((item) => {
              //
              if (item.AlbaranId && item.Facturado === '0') {
                this.llamarPeticionDePago2();
                this.albaranService
                  .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
                  .subscribe(() => {
                    this.albaranService.anulacionTotal.next(false);
                  });
              } else if (item.AlbaranId && item.Facturado === '1') {
                this.llamarPeticionDePago2();
                this.facturaService
                  .pdfFactura(item)
                  .subscribe(async (facturas: any) => {
                    if (facturas.DatosResult) {
                      await this.printService.printFacturaPDF(
                        facturas.DatosResult
                      );
                    }
                  });
              }
              if (item.NumAlbaran) {
                this.imprimirJustificanteDevolucion(item.NumAlbaran, 'TOTAL');
                this.albaranService.refrescarAlbaran(oldNumAlbaran).subscribe();
              }
            });
        });
    } else {
      const response: any = await this.albaranService.anularAlbaran();
      if (response.AlbaranId && response.Facturado === '0') {
        this.llamarPeticionDePago2();
        this.albaranService
          .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
          .subscribe(() => {
            this.albaranService.anulacionTotal.next(false);
          });
      } else if (response.AlbaranId && response.Facturado === '1') {
        this.llamarPeticionDePago2();
        this.facturaService
          .pdfFactura(response)
          .subscribe(async (facturas: any) => {
            if (facturas.DatosResult) {
              await this.printService.printFacturaPDF(facturas.DatosResult);
            }
          });
      }
      if (response.NumAlbaran) {
        this.imprimirJustificanteDevolucion(response.NumAlbaran, 'TOTAL');
        this.albaranService.refrescarAlbaran(oldNumAlbaran).subscribe();
      }
    }
  }

  llamarPeticionDePago2() {
    const entradaConjunta =
      this.pagoService.opcionesPagoValue.controls['entradaConjunta'].value ||
      false;
    this.pagarSub = this.pagoService
      .pagar(entradaConjunta)
      .pipe(
        flatMap((albaran) => {
          if (
            this.pagoService.opcionesPagoValue.controls['tickets'].value
          ) {
            if(albaran.entradas > 20){
              return this.albaranService
                .getDatosAlbaranTicketsProductosArr(
                  albaran.AlbaranId,
                  albaran.NumAlbaran
                )
                .pipe(
                  flatMap((res) => {
                    this.ticketsProductosArr =
                      res.ListadoAlbaranTicketsProductos.map(
                        (item) => item.TicketsListado
                      );
  
                    const opciones: OpcionesImpresionQR = {
                      itemsReimprimir: this.ticketsProductosArr.join(','),
                    };
  
                    // Llamada para la impresión QR después de obtener los tickets
                    return this.pagoService
                      .impresionQR20(albaran.NumAlbaran, albaran.entradas, opciones)
                      .pipe(
                        map((codigosQR: any) => {
                          albaran.infoImpresion = codigosQR;
                          this.disabledEntrar = false;
                          this.imprimiendoTickets = false;
                          return albaran;
                        }),
                        catchError((err) => {
                          // Manejar error de impresión QR
                          this.disabledEntrar = false;
                          this.imprimiendoTickets = false;
                          return throwError(
                            () => new Error('Error al imprimir QR')
                          );
                        })
                      );
                  })
                );
            } else {
              const opciones: OpcionesImpresionQR = {};
              return this.pagoService.impresionQR(albaran.NumAlbaran, albaran.entradas, opciones)
                .pipe(
                  map((codigosQR: any) => {
                    albaran.infoImpresion = codigosQR;
                    this.disabledEntrar = false;
                    this.imprimiendoTickets = false;
                    return albaran;
                  }),
                  catchError((err) => {
                    // Manejar error de impresión QR
                    this.disabledEntrar = false;
                    this.imprimiendoTickets = false;
                    return throwError(() => new Error('Error al imprimir QR'));
                  })
                );
            }
          } else {
            return of(albaran);
          }
        }),
        concatMap((albaran) => {
          const identificador = albaran.Identificador;
          const identificadorTarjeta = identificador + '_CC';
          if (this.esPagoConTarjeta()) {
            this.pagoService.impresionTarjeta(identificadorTarjeta).toPromise();
          }
          if (this.pagoService.hayQueImprimirAlbaranValue) {
            this.pagoService
              .impresionJustificante(albaran.NumAlbaran, identificador)
              .toPromise();
          }
          return this.albaranService
            .getAlbaran(
              this.configuration.datosTPVPathValue.pkId,
              '',
              '',
              albaran.NumAlbaran
            )
            .pipe(
              map((datosAlbaran: any) => {
                albaran.datos = datosAlbaran;
                return albaran;
              })
            );
        })
      )
      //   .pipe(
      //   concatMap(albaran => {
      //     const identificador = albaran.Identificador;
      //     const identificadorTarjeta = identificador + '_CC';
      //     if(this.pagoService.opcionesPagoValue.controls['tickets'].value) {
      //         this.pagoService
      //         .impresionQR(albaran.NumAlbaran, albaran.entradas).pipe(
      //           map((codigosQR: any) => {
      //             albaran.infoImpresion = codigosQR;
      //             this.disabledEntrar = false;
      //             this.imprimiendoTickets = false;
      //             return albaran;
      //           }),
      //           catchError(err => {
      //             // Manejar error de impresión QR
      //             console.error('Error al imprimir QR', err);
      //             this.disabledEntrar = false;
      //             this.imprimiendoTickets = false;
      //             return throwError(() => new Error('Error al imprimir QR'));
      //           })
      //         )
      //     }
      //     if(this.esPagoConTarjeta()) {
      //       this.pagoService.impresionTarjeta(identificadorTarjeta).toPromise()
      //     }
      //     if (this.pagoService.hayQueImprimirAlbaranValue) {
      //       this.pagoService
      //         .impresionJustificante(albaran.NumAlbaran, identificador).toPromise();
      //     }
      //     return this.albaranService
      //       .getAlbaran(
      //         this.configuration.datosTPVPathValue.pkId, '','',
      //         albaran.NumAlbaran
      //       )
      //       .pipe(
      //         map((datosAlbaran: any) => {
      //           albaran.datos = datosAlbaran;
      //           return albaran;
      //         }),
      //       );
      //   })
      // )
      .subscribe((res: any) => {
        this.estaPagando = !this.estaPagando;
        this.disabledEntrar = false;
        if (Object.entries(res.datos).length !== 0) {
          // Yaribel 20210311 Cambiamos el orden de apertura de los modales
          if (this.pagoService.hayQueFacturarValue === true) {
            this.modalService.open('facturar');
          }
          if (this.pagoService.hayQueFacturarSimpleValue === true) {
            let cliente = {};
            if (this.ventaService.clienteSeleccionadoValue) {
              this.facturaService
                .buscarClienteXCodigo(this.ventaService.clienteSeleccionadoValue.Codigo)
                .pipe(
                  take(1),
                  switchMap((res: any) => {
                    if (res.ResultadoBuscadorClientes && res.ResultadoBuscadorClientes.length === 1) {
                      const clienteEncontrado = res.ResultadoBuscadorClientes[0];
                      if (clienteEncontrado.Codigo > 0) {
                        cliente = {
                          codigoLegal: clienteEncontrado.CIFoDNIFiscal,
                          nombre: clienteEncontrado.NombreClienteFiscal,
                          direccion: clienteEncontrado.DomicilioFiscal,
                          ciudad: clienteEncontrado.LocalidadFiscal,
                          codigoPostal: clienteEncontrado.CPFiscal,
                          provincia: clienteEncontrado.ProvinciaFiscal,
                          comentarios: '',
                          codigoCliente: clienteEncontrado.Codigo,
                          pais: this.getNombrePais(clienteEncontrado.PaisFiscal),
                        };
                        return this.facturaService.crearFacturaSimplificada(cliente, 1, true);
                      } else {
                        return of(null);
                      }
                    } else {
                      //return this.facturaService.crearFacturaSimplificada(cliente, 1, true);
                      return of(null);
                    }
                  })
                )
                .subscribe((res) => {
                  if (res && res.DatosResult) {
                    this.facturaService.impresionFactura(res.DatosResult).subscribe(
                      (response) => {
                        this.albaranService
                          .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
                          .subscribe();
                      },
                      (error) => {
                        this.mostrarError(error);
                      }
                    );
                  }
                });
            } else {
              this.facturaService.crearFacturaSimplificada(cliente, 1).pipe(first()).subscribe(
                (res) => {
                  if (res && res.DatosResult) {
                    this.facturaService.impresionFactura(res.DatosResult).subscribe(
                      (response) => {
                        this.albaranService
                          .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
                          .subscribe();
                      },
                      (error) => {
                        this.mostrarError(error);
                      }
                    );
                  }
                },
                (error) => {
                  this.mostrarError(error);
                }
              );
            }
          }
          
          if (this.pagoService.hayContactosValue === true) {
            this.modalService.open('datoscontacto');
          }
          if (
            this.ventaService.carritoValue.entradas.filter(
              (e) => e.entrada.noEditable
            ).length !== 0
          ) {
            this.modalService.open('confirmarEntradasLocalizador');
          }
          if (this.pagoService.hayQueImprimirAlbaranValue) {
            this.cabeceraSub = this.pagoService.datosCabecera().subscribe(
              (data) => {
                // if (this.ventaService.carritoValue.precioPrepago) {
                //   this.printService.printPurchaseAlbaran(
                //     res,
                //     data
                //   );
                // } else {
                //   this.printService.printPurchaseAlbaran(res, data);
                // }
                //Yaribel 20210202 añadimos impresion de justicante de saldo
                if (res.datos.AlbaranFOPs.find((x) => x.FOP == 'TM')) {
                  const codigo = res.datos.AlbaranFOPs.find(
                    (x) => x.FOP == 'TM'
                  ).TarjetaMonederoCodigo;
                  let saldo = 0;
                  this.validarTarjetaMonederoSub = this.pagoService
                    .validarTarjetaMonedero(codigo)
                    .subscribe((respuesta) => {
                      if (respuesta !== undefined && respuesta.DatosResult) {
                        saldo = respuesta.DatosResult.SaldoDisponible;
                        this.printService.imprimirSaldoMonedero(codigo, saldo);
                      }
                      this.validarTarjetaMonederoSub.unsubscribe();
                    });
                }
              },
              (error) => {
                this.disabledEntrar = false;
                this.imprimiendoTickets = false;
                // Toma acciones adicionales como mostrar un mensaje de error al usuario
                console.error('Error al imprimir el QR:', error);
              }
            );

            if (
              this.carritoOrigen &&
              this.carritoOrigen.reservaPagada &&
              this.carritoOrigen.reservaPagada == '1'
            ) {
              // this.reImprimirTicketsSub = this.localizadorService
              // .reimprimirTickets(this.localizadorService.localizadorValue.ClaveReserva)
              // .subscribe((infoImpresion) => {
              //   //<<Yaribel 20210126 unificamos la impresion de tickets en uno solo
              //   // this.printService.imprimirTicketUnico(
              //   //   this.configuration.datosTPVPathValue.Nombre,
              //   //   this.configuration.datosTPVPathValue.pkId,
              //   //   '',
              //   //   infoImpresion,
              //   //   this.localizadorService.localizadorValue.FechaHoraEntrada.split(' ')[1],
              //   //   this.localizadorService.localizadorValue.ReservaItems,
              //   //   this.localizadorService.localizadorValue.ClaveReserva,
              //   //   moment(this.localizadorService.localizadorValue.ReservaHistorial[0].Fecha).format('DD/MM/YYYY'),
              //   //   this.localizadorService.localizadorValue.ReservaHistorial[0].Hora.slice(0, -3),
              //   //   this.localizadorService.localizadorValue.ReservaRecintos,
              //   //   this.localizadorService.localizadorValue.ReservaMotivos,
              //   //   this.localizadorService.localizadorValue.Localizador,
              //   //   "0" //this.albaran.TipoImpresion
              //   // );
              //   // Yaribel 20210126 >>
              //   if(this.reImprimirTicketsSub) {this.reImprimirTicketsSub.unsubscribe();}
              // });
            }
          }
          if (this.pagoService.opcionesPagoValue.controls['tickets'].value) {
            const entradasDeVenta =
              this.ventaService.carritoValue.entradas.filter(
                (e) => !e.entrada.noEditable // TODO Yaribel 20210120 consultar porque se gestiona asi,  no existe noEditable en el objeto
              );

            //<<Yaribel 20210126 unificamos la impresion de tickets en uno solo
            // this.printService.imprimirTicketUnico(
            //       this.configuration.datosTPVPathValue.Nombre,
            //       this.configuration.datosTPVPathValue.pkId,
            //       res.NumAlbaran,
            //       res.infoImpresion,
            //       res.datos.FechaHoraEntrada.split(' ')[1],
            //       res.datos.AlbaranItems, //entradasDeVenta,   // TODO consultar porque se gestiona asi Yaribel 20210120 Modificamos entradasDeVenta por el items para recibir entradas e imprimir precio
            //       res.Identificador,
            //       moment(res.datos.AlbaranHistorial[0].Fecha).format(
            //         'DD/MM/YYYY'
            //       ),
            //       res.datos.AlbaranHistorial[0].Hora.slice(0, -3),
            //       res.datos.AlbaranRecintos,
            //       res.datos.AlbaranMotivos,
            //       res.datos.Localizador.length > 0? res.datos.Localizador : res.datos.NumVenta,
            //       res.datos.TipoImpresion
            //     );
            // Yaribel 20210126 >>
            if (
              this.ventaService.carritoValue.entradas.filter(
                (e) => e.entrada.noEditable
              ).length !== 0
            ) {
              this.modalService.open('confirmarEntradasLocalizador');
            }
          }
          if (this.pagoService.HayQueImprimirEtiquetasValue) {
            // this.modalService.open('etiquetas');
          }
          this.disabledEntrar = false;
          this.ventaService.carritoModificado = false;
          this.ventaService.setHayDescuento(false); //Yaribel 20210730  cliente Mapfre VSTS 8370
          this.stepperNavigation.desbloquearNavegacionPorPago();
          this.pagoService.setPagoTotal(undefined);
          this.pagoService.setCambio(undefined);
          this.pagoService.setDiferencia(undefined);
          this.pagoService.resetPagoDesglosado();
          this.ventaService.setFechaSeleccionadaValue();
          this.ventaService.setClienteSeleccionadoValue('');
          this.ventaService.currentCliente = '0';
          this.ventaService.actualizarCantidadVentas(res.cambioDevuelto);
          this.ventaService.currentRecinto = undefined;
          this.stepperNavigation.resetearPasos();
          this.carrito.resetearCarrito();
          this.ventaService.setCarritoOrigen(new Carrito());
          this.butacaService.removeAllButaca();
          this.motivosService.setMotivosSeleccionados([]);
          this.sidebarNavigation.unblockSecciones();
          this.stepperNavigation.continuarVenta.next(false);
          this.procedenciaService.setProcedenciasValue(undefined);

          if (this.configuracion.getConfig('COMPRAINICIO')) {
            this.router.navigate([
              this.configuracion.getConfig('COMPRAINICIO'),
            ]);
          } else {
            this.router.navigate(['']);
          }
        } else {
          if (res.Mensajes[0].DescripcionMensaje.includes('Servicio Técnico')) {
            this.toast.info(
              'Ha sido imposible conectarse a la Escuela. Por favor, avise al Servicio Técnico',
              'Info',
              {
                toastComponent: InfoToast,
                timeOut: 7500,
              }
            );
          } else if (
            res.Mensajes[0].DescripcionMensaje.includes(
              'No hay aforo para el horario:'
            )
          ) {
            let posicion = res.Mensajes[0].DescripcionMensaje.indexOf(
              'No hay aforo para el horario:'
            );
            this.toast.info(
              'No ha sido posible insertar en escuela. ' +
                res.Mensajes[0].DescripcionMensaje.substring(posicion),
              'Info',
              {
                toastComponent: InfoToast,
                timeOut: 7500,
              }
            );
          } else {
            this.disabledEntrar = false;
            this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
              toastComponent: ErrorToast,
              timeOut: 5000,
            });
          }
        }
      });
  }

  getNombrePais (codigo){
    let NombrePais = codigo;
    let pais: pais;
    if(codigo && codigo != ''){
      pais = this.listadoPaises.find(x=> x.CodPais === codigo);
      if(pais && pais.NombrePais)
      NombrePais = pais.NombrePais;
    }
    else{
      NombrePais = '';
    }
    return NombrePais;
  }

  mostrarError(error: any) {
    if ([204, 400, 401, 404, 500].includes(error.status)) {
      this.toastr.error('Error', this.translateService.instant('ERROR_AVISAR_ENCARGADO'), {
        toastComponent: ErrorToast,
      });
    } else {
      this.toastr.error(
        'Error',
        this.translateService.instant('ERROR_DESCONOCIDO'), {
        toastComponent: ErrorToast,
      });
    }
  }


  async llamarPeticionDePago() {
    // Si se cumple la condicion se debe anular el albaran
    try {
      if (
        this.ventaService.carritoValue.albaran &&
        this.ventaService.carritoValue.albaran.MediosPago &&
        this.ventaService.carritoValue.albaran.pkId &&
        this.ventaService.carritoValue.albaran.pkId != ''
      ) {
        //
        this.anularVenta();
      } else {
        await this.llamarPeticionDePago2();
      }
    } catch (error) {
      throw new Error(error);
    }

    //this.imprimiendoTickets = false;
  }

  sePuedeFinalizar() {
    // Apaño para precio 0
    if (
      this.ventaService.carritoValue.entradas.length > 0 &&
      this.ventaService.carritoValue.precioTotal == 0 &&
      !this.ventaService.carritoValue.carrito &&
      this.ventanaActualDePago
    ) {
      return false;
    }
    return (this.pagoService.diferenciaValue === 0 ||
      this.ventaService.carritoValue.precioTotal === 0) &&
      this.ventanaActualDePago &&
      this.pagoService.formularioCorrectoValue &&
      !this.estaPagando
      ? false
      : true;
  }

  conversor(precio: string) {
    return Number.parseFloat(precio.replace(/,/, '.'));
  }

  getFechaSeleccionada() {
    if (this.ventaService.fechaSeleccionadaValue) {
      return moment(this.ventaService.fechaSeleccionadaValue)
        .locale(this.idioma)
        .format('L');
    }
  }

  getFecha() {
    const fechaHoy = moment();
    if (this.ventaService.fechaSeleccionadaValue === '') {
      this.paseHorarioSeleccion = '';
      return this.translateService.instant('VentaSinFecha');
    }
    if (fechaHoy.isSame(this.ventaService.fechaSeleccionadaValue, 'day')) {
      if (
        this.ventaService.carritoValue.horarios.filter((x) => x.resumen !== '')
          .length == 0
      )
        return (this.paseHorarioSeleccion = this.translateService.instant(
          'SIN_SELECCION_PASE_HORARIO'
        ));
    }
    return moment(this.ventaService.fechaSeleccionadaValue)
      .locale(this.idioma)
      .format('dddd D, MMMM YYYY');
  }

  getNombreCliente() {
    if (this.ventaService.clienteSeleccionadoValue) {
      return `${this.ventaService.clienteSeleccionadoValue.NombreCliente}`;
    }
    return '';
  }

  getLocalizador() {
    return this.ventaService.carritoValue.localizador
      ? this.ventaService.carritoValue.localizador.ClaveReserva
      : '';
  }

  getDescuentoFromCliente(cliente) {
    if (cliente.ConDescuento === 'True') {
      let tipoDto = '';
      switch (cliente.TipoDto) {
        case 'DT':
          tipoDto = ` %`;
          break;
        case 'PR':
          tipoDto = `,00 €`;
          break;
        default:
          break;
      }
      return `- ${this.ventaService.clienteSeleccionadoValue.Dto}${tipoDto}`;
    }
    return ``;
  }

  mostrarFinalizarPagar() {
    return 'PAGAR';
  }

  test() {
    return;
  }

  inputDeTeclado(event: any, entrada: Entrada) {
    event.preventDefault();
    const charCode = event.keyCode;
    this.numpadService.selected = event.srcElement;
    this.numpadService.entrada = entrada;
    this.formControlService.setCurrentFormGroup(
      null,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );

    if (this.isDeleteButtonPressed(charCode)) {
      this.formControlService.deleteChar();
      return;
    }

    if (this.isEnterButton(charCode)) {
      this.formControlService.unfocus();
      event.srcElement.blur();
      return;
    }

    if (this.isNumberKey(charCode)) {
      this.formControlService.inputChar(event.key);
      return;
    }
  }

  isNumberKey(charCode: number) {
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isDeleteButtonPressed(charCode: number) {
    return charCode === 8 ? true : false;
  }

  isEnterButton(charCode: number) {
    return charCode === 13 ? true : false;
  }

  checkIfModalOpen() {
    return this.modalService.exists('selector-mas-entradas')
      ? this.modalService.isOpen('selector-mas-entradas')
      : false;
  }

  closeModal() {
    if (
      this.modalService.exists('selector-mas-entradas') &&
      this.modalService.isOpen('selector-mas-entradas')
    ) {
      this.modalService.close('selector-mas-entradas');
    }
  }

  getFechaHora(entrada) {
    const fecha = moment(
      entrada.FechaHoraEntrada,
      'YYYY/MM/DD HH:mm:ss'
    ).format('L');
    const hora = moment(entrada.FechaHoraEntrada, 'YYYY/MM/DD HH:mm:ss').format(
      'HH:mm'
    );
    return fecha + ' - ' + hora;
  }

  pad(s, size) {
    while (s.length < (size || 2)) {
      s = '0' + s;
    }
    return s;
  }
  mapeaListaentradas2ListaProductos(listaentradas, filtroProducto?): string[] {
    let respuesta: string[] = [];
    listaentradas.forEach((element) => {
      let idProducto = '';
      if (element.entrada.TipoProducto == '2') {
        if (
          !filtroProducto ||
          filtroProducto == element.entrada.TipoPromocionId
        )
          idProducto =
            '2,' +
            element.entrada.TipoPromocionId +
            ',' +
            element.cantidad.toString();
      } else if (element.entrada.TipoProducto == '3') {
        if (!filtroProducto || filtroProducto == element.entrada.TipoAbonadoId)
          idProducto =
            '3,' +
            element.entrada.TipoAbonadoId +
            ',' +
            element.cantidad.toString();
      } else {
        if (!filtroProducto || filtroProducto == element.entrada.TipoEntradaId)
          idProducto =
            '1,' +
            element.entrada.TipoEntradaId +
            ',' +
            element.cantidad.toString();
      }
      respuesta.push(idProducto);
    });
    //if (respuesta.length > 0) respuesta = respuesta.slice(0, -1);
    return respuesta;
  }

  fechaYYYYMMDD2slash(fecha, hora) {
    let respuesta = '';
    if (fecha && fecha.length == 8) {
      respuesta =
        fecha.substring(0, 4) +
        '/' +
        fecha.substring(4, 6) +
        '/' +
        fecha.substring(6);
    }
    if (hora && hora.length == 5) {
      respuesta = respuesta + ' ' + hora + ':00';
    } else if (hora && hora.length == 8) {
      respuesta = respuesta + ' ' + hora;
    } else {
      respuesta = respuesta + ' ' + '23:59:00';
    }
    return respuesta;
  }

  imprimirJustificanteDevolucion(NumAlbaran: string, tipo: string) {
    //TODO  Yaribel 20201124 mejorar mapear el objeto y no usar refrescarAlbaran
    this.albaranService
      .refrescarAlbaran(NumAlbaran)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          const albaranValue = this.albaranService.albaranValue;
          const NombreTPV = this.albaranService.albaranValue.NombreTPV;
          const IdTPV = this.albaranService.albaranValue.IdTPV;
          this.pagoService
            .datosCabecera()
            .pipe(take(1))
            .subscribe((data) => {
              switch (tipo) {
                case 'TOTAL':
                  this.printService.printRefundAlbaran(
                    {
                      datos: albaranValue,
                      NombreTPV: NombreTPV,
                      IdTPV: IdTPV,
                    },
                    data
                  );
                  break;
                case 'PARCIAL':
                  this.printService.printPartialRefund(
                    {
                      datos: albaranValue,
                    },
                    data
                  );
                  break;
              }
            });
        }
      });
  }

  agruparCaracteristicas(CaracteristicasEntrada) {
    const nuevo = [];
    CaracteristicasEntrada.forEach((caracteristica) => {
      if (
        !nuevo.find(
          (cat) =>
            cat.Tipo === caracteristica.Tipo &&
            cat.Rango === caracteristica.Rango &&
            cat.Nivel === caracteristica.Nivel
        )
      ) {
        nuevo.push(caracteristica);
      }
    });
    return nuevo;
  }

  verCarrito() {
    if (
      (!this.carrito.reservaPagada || this.carrito.reservaPagada == '0') &&
      this.carrito.reservaAgrupada &&
      this.carrito.reservaAgrupada == '1'
    ) {
      return this.carrito.entradas;
    } else {
      return this.carritoOrigen.entradas;
    }
  }

  disableButtonEnter() {
    return this.disabledEntrar;
  }
}
