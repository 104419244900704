import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { first, takeWhile } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { UsuarioElevadoService } from 'src/app/core/security/usuario-elevado.service';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { InfoToast } from 'src/app/core/toast/info.toast';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import { ClienteService } from 'src/app/shared/services/cliente.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SidebarNavigationService } from 'src/app/shared/services/sidebar-navigation.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { VisorService } from 'src/app/shared/services/visor.service';
import { Carrito } from '../..';
import { Carrito2 } from '../../models/carrito.model';
import { PagoService } from '../../services/pago.service';
import { StepperNavigationService } from '../../services/stepper-navigation.service';
import { VentaService } from '../../services/venta.service';
import CodIsoEnum from 'src/app/shared/enums/codIsoEnum';
import { MyCurrencyPipe } from 'src/app/shared/pipes/myCurrency.pipe';

@Component({
  selector: 'ticketing-selector-pago',
  templateUrl: './selector-pago.component.html',
  styleUrls: ['./selector-pago.component.scss'],
})
export class SelectorPagoComponent
  implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit {
  @ViewChild('recibidoInput') private elementRef: ElementRef;
  pagosForm: FormGroup;
  optionsForm: FormGroup;
  promocionalesForm: FormGroup;
  pagosFormVenta: FormGroup;
  busquedaAlbaranForm: FormGroup;
  cambio: number;
  diferencia: number;
  pagoTotal = 0;
  precioTotal = 0;
  emitirFactura = false;
  emitirFacturaSimple = false;
  emitirAlbaran = true;
  pedirContacto = false;
  esVentaModificada = false;
  valorEfectivo = 0;
  conf: any;
  camposObligatorios = '0';
  esEntradaConjunta = false; // Yaribel 20210216 Añadimos variable para obtener valores de configuracion para entrada conjunto
  private alive = true;
  precioPrepago: any;
  saldoMonedero: number;
  monederoForm: FormGroup;
  errorCodigoMonedero: string = '';
  codigoTarjetaMonedero: string = '';
  TMActivo = false;
  TMErroneo = false;
  TMCaducado = false;
  fieldsList: any;
  descuento = 0;
  totalConDescuento = 0;
  totalSinDescuento = 0;
  aplazadoVisual = '';
  placeholderValue: string;
  divisaSimboloDecimal: string;
  divisaSimbSeparacionMiles: string;
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  divisaSimboloDerecha: string;
  divisasbOpsTarjeta: boolean;
  codigoTarjeta: string;
  // optionsTipoDoc = ['NIF', 'NIE'];
  getTiposEntradaDatosSub: Subscription;
  pagosFormSub: Subscription;
  validarTarjetaMonederoSub: Subscription;
  haSidoElevadoSub: Subscription;

  constructor(
    public venta: VentaService,
    private formBuilder: FormBuilder,
    private stepper: StepperNavigationService,
    private pago: PagoService,
    private formControlService: FormControlService,
    private permisosService: PermisosService,
    private user: UserManagerService,
    private usuarioElevadoService: UsuarioElevadoService,
    private toastService: ToastrService,
    private visor: VisorService,
    private modalService: ModalService,
    private router: Router,
    private sidebarNavigation: SidebarNavigationService,
    private configuration: ConfigurationService,
    private config: AppConfiguration,
    private albaranService: AlbaranService,
    private cliente: ClienteService,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private myCurrencyPipe: MyCurrencyPipe
  ) { }

  ngOnInit() {
    this.conf = this.configuration.appConfig.value;
    this.stepper.bloquearNavegacionPorPago();
    this.sidebarNavigation.blockSecciones(this.router.url);
    this.divisaSimbSeparacionMiles = this.configuration.divisasValue[0].SimbSeparacionMiles;
    this.divisaSimboloDecimal = this.configuration.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configuration.divisasValue[0].simbolo;
    this.divisaDecimales = Number(this.configuration.divisasValue[0].NumeroDecimales)
    this.divisaPosicion = this.configuration.divisasValue[0].PosicionDivisa;
    this.divisaSimboloDerecha = this.configuration.divisasValue[0].SimboloDerecha;
    this.divisasbOpsTarjeta = this.configuration.divisasValue[0].OpsTarjeta.toLowerCase() === 'true';
    this.placeholderValue = this.myCurrencyPipe.transform(0, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimboloDerecha, this.divisaSimbSeparacionMiles)
    this.emitirAlbaran =
      this.conf.DatosResult.AppConfigJsonPago.ImprimirAlbaranXDefecto &&
        this.conf.DatosResult.AppConfigJsonPago.ImprimirAlbaranXDefecto == '1'
        ? true
        : false;
    this.emitirFactura =
      this.conf.DatosResult.AppConfigJsonPago.GenerarFacturaXDefecto &&
        this.conf.DatosResult.AppConfigJsonPago.GenerarFacturaXDefecto == '1' &&
        this.tienePermiso('VentaFacturar')
        ? true
        : false;
    this.emitirFacturaSimple = this.conf.DatosResult.AppConfigJsonPago.ImprimirFacturaSimpXDefecto &&
      this.conf.DatosResult.AppConfigJsonPago.ImprimirFacturaSimpXDefecto == '1' &&
      this.tienePermiso('VentaFacturar')
      ? true
      : false;
    this.camposObligatorios =
      this.configuration.deviceConfiguration.AppConfigJsonConfig.Documentacion;
    this.codigoTarjeta = this.translate.instant('CODIGO_TARJETA');
    if (this.camposObligatorios == '1' || this.camposObligatorios == '2') {
      this.changePedirContacto();
    }
    if (this.conf.DatosResult.AppConfigJsonPago.ConjuntaXdefecto == '1') {
      this.esEntradaConjunta = true;
    }


    // creacion de dos arrays, uno con todas las entradas y otro con solo las nominativas
    // en estos arrays se incluira los datos nominativos
    // y se volvera a integrar en el carrito en el componente del modal de datos adicionales
    const entradasArrCompleto = [...this.venta.carritoValue.entradas];

    this.getTiposEntradaDatosSub = this.venta
      .getTiposEntradaDatos()
      .subscribe((data) => {
        this.onTiposEntradasDatosChange(data, entradasArrCompleto);
      });

    this.pagosForm = this.formBuilder.group({
      prepago: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      aplazado: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      transferencia: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      pinpad: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      datafono: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      monedero: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      saldoMonedero: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      codigoMonedero: [
        '',
        Validators.compose([Validators.pattern('^[A-Za-z0-9]*$')]),
      ],
      efectivo: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
    });

    this.pagosForm.get('aplazado').valueChanges.subscribe((valor: string) => {
      this.actualizarAplazadoVisual(valor);
    });

    this.promocionalesForm = this.formBuilder.group({
      codigopromocional: [
        '',
        Validators.compose([Validators.pattern('^[A-Za-z0-9]')]),
      ],
      aplicarcodigopromocional: ['Aplicar', ''],
    });

    this.pagosFormVenta = this.formBuilder.group({
      prepagoVenta: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      aplazadoVenta: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      trasferenciaVenta: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      tarjetaVenta: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      monederoVenta: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
      recibidoVenta: [
        '',
        Validators.compose([
          Validators.min(0),
          Validators.pattern(`^[0-9]+(.[0-9]{0,${this.divisaDecimales}})?`),
        ]),
      ],
    });

    this.optionsForm = this.formBuilder.group({
      tickets: true,
      cortarTickets: true,
      entradaConjunta: this.esEntradaConjunta,
      etiquetas: false,
    });

    this.venta.carrito
      .pipe(takeWhile(() => this.alive))
      .subscribe((carrito) => {
        let precioTotal = 0;
        carrito.entradas.forEach((entrds) => {
          if (!entrds.entrada.noEditable) {
            precioTotal =
              precioTotal +
              Number.parseFloat(
                entrds.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
              ) *
              entrds.cantidad;
          }
        });
        this.precioTotal = Number.parseFloat(precioTotal.toFixed(this.divisaDecimales));
        this.cambio =
          this.pagoTotal -
          precioTotal -
          (this.venta.carritoValue.importeDescuento
            ? this.venta.carritoValue.importeDescuento
            : 0);
        this.cambio = Math.round(this.cambio * 100) / 100;
        this.precioPrepago = carrito.precioPrepago ? carrito.precioPrepago : 0;
        this.pagosForm.controls['prepago'].patchValue(
          carrito.precioPrepago ? carrito.prepago : this.formatNumber(0, this.divisaDecimales)
        );
      });

    this.formControlService.setCurrentFormGroup(
      this.pagosForm,
      document.getElementById('RecibidoInput'),
      document.getElementById('RecibidoInput')
        ? document.getElementById('RecibidoInput').getAttribute('category')
        : ''
    );
    this.pago.resetPagoDesglosado();
    this.pago.setPagoTotal(0);
    this.pago.setDiferencia(0 - this.precioTotal);
    this.pago.setCambio(0 - this.precioTotal);
    this.pago.setCodigoTarjetaMonederoValue(this.codigoTarjetaMonedero); // Yaribel 30102020  cargamos el codigo de la tarjeta Monedero
    this.onChanges();
    this.visor.mostrarDisplayTotalAlbaran(this.precioTotal);
    this.pago.setHayQueFacturarSimpleValue(this.emitirFacturaSimple);
    this.pago.setHayQueFacturarValue(this.emitirFactura);
    this.pago.setHayQueImprimirAlbaranValue(this.emitirAlbaran);
    this.pago.setOpcionesPagoValue(this.optionsForm);
    if (
      this.venta.carritoValue.albaran &&
      this.venta.carritoValue.albaran.MediosPago &&
      this.venta.carritoValue.albaran.pkId &&
      this.venta.carritoValue.albaran.pkId != ''
    ) {
      this.esVentaModificada = true;
      this.venta.carritoValue.albaran.MediosPago.forEach((item) => {
        const importe = Number.parseFloat(item.Importe.replace(',', '.'));
        if (item.FOP == 'CC') {
          this.pagosFormVenta.controls['tarjetaVenta'].patchValue(
            importe > 0 ? importe.toFixed(this.divisaDecimales).replace('.', ',') : '0,00'
          );
        } else if (item.FOP == 'CA') {
          this.pagosFormVenta.controls['recibidoVenta'].patchValue(
            importe > 0 ? importe.toFixed(this.divisaDecimales).replace('.', ',') : '0,00'
          );
        } else if (item.FOP == 'TR') {
          this.pagosFormVenta.controls['trasferenciaVenta'].patchValue(
            importe > 0 ? importe.toFixed(this.divisaDecimales).replace('.', ',') : '0,00'
          );
        } else if (item.FOP == 'PR') {
          // Yaribel 20201217 modificamos la forma de pago IN por PR
          this.pagosFormVenta.controls['prepagoVenta'].patchValue(
            importe > 0 ? importe.toFixed(this.divisaDecimales).replace('.', ',') : '0,00'
          );
        } else if (item.FOP == 'TM') {
          //Yaribel 28102020 añadimos la validación de FOP cuando es TM
          this.pagosFormVenta.controls['monederoVenta'].patchValue(
            importe > 0 ? importe.toFixed(this.divisaDecimales).replace('.', ',') : '0,00'
          );
        } else if (item.FOP == 'DP') {
          this.pagosFormVenta.controls['aplazadoVenta'].patchValue(
            importe > 0 ? importe.toFixed(this.divisaDecimales).replace('.', ',') : '0,00'
          );
        }
      });
      //
    }

    this.pagosForm.enable();
    const carritoConFechasAñadidas = this.venta.carritoValue;
    this.venta.carritoValue.entradas.forEach((element) => {
      if (carritoConFechasAñadidas.horarios.length === 0) {
        if (element.entrada.TipoProducto === '2') {
          element.entrada.PromocionEntradas.forEach((item) => {
            const fecha2 = moment(
              moment(element.entrada.Fecha, 'YYYY/MM/DD').format('YYYY/MM/DD') +
              ' ' +
              moment().format('HH:mm:ss'),
              'YYYY/MM/DD HH:mm:ss'
            ).format('YYYY/MM/DD HH:mm:ss');
            item.FechaHoraAcceso = fecha2;
            element.entrada.FechaHoraAcceso = fecha2;
          });
        } else if (element.entrada.TipoProducto === '1') {
          // const fecha1 = moment(
          //   moment(element.entrada.Fecha, 'YYYY/MM/DD').format('YYYY/MM/DD') +
          //     ' ' +
          //     moment().format('HH:mm:ss'),
          //   'YYYY/MM/DD HH:mm:ss'
          // ).format('YYYY/MM/DD HH:mm:ss');
          const fecha1 = element.entrada.Fecha !== '' ? moment(
            moment(element.entrada.Fecha, 'YYYY/MM/DD').format('YYYY/MM/DD') +
            ' ' +
            moment().format('HH:mm:ss'),
            'YYYY/MM/DD HH:mm:ss'
          ).format('YYYY/MM/DD HH:mm:ss')
            : moment().format('YYYY/MM/DD HH:mm:ss');

          element.entrada.FechaHoraAcceso = fecha1;
        }
        // se pone la fecha en la raiz del carrito
        const fecha = moment(
          moment(element.entrada.Fecha, 'YYYY/MM/DD').format('YYYY/MM/DD') +
          ' ' +
          moment().format('HH:mm:ss'),
          'YYYY/MM/DD HH:mm:ss'
        ).format('YYYY/MM/DD HH:mm:ss');
        carritoConFechasAñadidas.FechaHoraAcceso = fecha;
      }

      if (carritoConFechasAñadidas.horarios.length > 0) {
        const todosLosHorarios = [];
        carritoConFechasAñadidas.horarios.forEach((item) => {
          todosLosHorarios.push(
            moment(item.sesion.Fecha).format('YYYY/MM/DD') +
            ' ' +
            item.sesion.HoraInicio +
            ':00'
          );
        });
        //
        const moments = todosLosHorarios.map((d) =>
          moment(d, 'YYYY/MM/DD HH:mm:ss')
        );
        const fechaMenor = moment.min(moments).format('YYYY/MM/DD HH:mm:ss');
        carritoConFechasAñadidas.entradas.forEach((item) => {
          // fechas en los bonos
          if (item.entrada.TipoProducto === '2') {
            let bonoTieneFecha = '';
            item.entrada.PromocionEntradas.forEach((ele) => {
              carritoConFechasAñadidas.horarios.forEach((elem) => {
                if (elem.recinto.entradas.includes(ele.TipoEntradaId)) {
                  bonoTieneFecha =
                    moment(elem.sesion.Fecha).format('YYYY/MM/DD') +
                    ' ' +
                    elem.sesion.HoraInicio +
                    ':00';
                }
              });
            });
            if (bonoTieneFecha !== '') {
              item.entrada.PromocionEntradas.forEach((ele) => {
                ele.FechaHoraAcceso = bonoTieneFecha;
                item.entrada.FechaHoraAcceso = bonoTieneFecha;
              });
            } else if (bonoTieneFecha === '') {
              item.entrada.PromocionEntradas.forEach((ele) => {
                if (
                  this.comprobarEntradaTieneHorario(
                    ele,
                    carritoConFechasAñadidas
                  )
                ) {
                  ele.FechaHoraAcceso = fechaMenor;
                  item.entrada.FechaHoraAcceso = fechaMenor;
                } else {
                  ele.FechaHoraAcceso = moment().format('YYYY/MM/DD HH:mm:ss');
                  item.entrada.FechaHoraAcceso = moment().format(
                    'YYYY/MM/DD HH:mm:ss'
                  );
                }
              });
            }
          }
          // Apaño para butacas
          if ('fechaAuditorio' in item.entrada) {
            item.entrada.FechaHoraAcceso = item.entrada.FechaHoraEntrada;

          } else if (item.entrada.TipoProducto === '1') {
            let tieneFecha = '';
            carritoConFechasAñadidas.horarios.forEach((elem) => {
              if (elem.recinto.entradas) {
                if (elem.recinto.entradas.includes(item.entrada.TipoEntradaId)) {
                  tieneFecha =
                    moment(elem.sesion.Fecha).format('YYYY/MM/DD') +
                    ' ' +
                    elem.sesion.HoraInicio +
                    ':00';
                }
                if (tieneFecha !== '') {
                  item.entrada.FechaHoraAcceso = fechaMenor;
                } else if (tieneFecha === '') {
                  item.entrada.FechaHoraAcceso = fechaMenor;
                }
              }
            });
          }
        });
        carritoConFechasAñadidas.FechaHoraAcceso = fechaMenor;
      }
    });

    if (!this.divisasbOpsTarjeta) {
      this.pagosForm.get('pinpad').disable()
      this.pagosForm.get('datafono').disable()
    }
  }

  // manieva 11595
  // solamente lo exporte metodo para que no este en el ngonit
  // no tiene cambios
  private onTiposEntradasDatosChange(data: any, entradasArrCompleto: any[]) {
    this.fieldsList = data;
    //
    //
    const entradasArrNominativas = [];
    if (Object.keys(this.fieldsList).length > 0) {
      entradasArrCompleto.forEach((entrada) => {
        //
        if (entrada.entrada.TipoProducto === '1' &&
          entrada.entrada.TEDatosId !== undefined &&
          entrada.entrada.TEDatosId !== null &&
          entrada.entrada.TEDatosId !== '' &&
          entrada.entrada.CuentaNominada !== '0') {
          let indice = 0;

          for (let i = 0; i < entrada.cantidad; i++) {
            const itemCaracteristica = [];
            let TEDatosIdEncontrado = false;
            //
            const entradaItem = Object.assign({}, entrada.entrada);
            if (entrada.entrada.CaracteristicasEntrada &&
              entrada.entrada.CaracteristicasEntrada.length > 0) {
              itemCaracteristica.push(
                entrada.entrada.CaracteristicasEntrada[indice]
              );
              entradaItem.CaracteristicasEntrada = itemCaracteristica;
            }
            const itemEVPersona = [];
            if (entrada.entrada.EntradasVendidasPersonas &&
              entrada.entrada.EntradasVendidasPersonas.length >= indice + 1) {
              itemEVPersona.push(
                entrada.entrada.EntradasVendidasPersonas[indice]
              );
            }
            entradaItem.EntradasVendidasPersonas = itemEVPersona;
            //
            this.fieldsList.ListadoTiposEntradaDatos.forEach((i) => {
              if (i.TEDatosId === entrada.entrada.TEDatosId) {
                TEDatosIdEncontrado = true; //si coincide el tbTipoEntradaDatos con la entrada que evaluamos
                const formularioJson = JSON.parse(i.TiposEntradaDatosJson);
                if (formularioJson.TipoNominacion != undefined &&
                  formularioJson.TipoNominacion === 'GRUPO') {
                  if (indice === 0) {
                    entradasArrNominativas.push(entradaItem);
                  }
                } else {
                  entradasArrNominativas.push(entradaItem);
                }
              }
            });
            if (!TEDatosIdEncontrado) {
              entradasArrNominativas.push(entradaItem);
            }
            indice++;
          }
        } else if (entrada.entrada.TipoProducto === '2') {
          let indice = 0;
          for (let i = 0; i < entrada.cantidad; i++) {
            const itemCaracteristica = [];
            //
            const entradaItem = Object.assign({}, entrada.entrada);
            if (entrada.entrada.CaracteristicasEntrada &&
              entrada.entrada.CaracteristicasEntrada.length > 0) {
              itemCaracteristica.push(
                entrada.entrada.CaracteristicasEntrada[indice]
              );
              entradaItem.CaracteristicasEntrada = itemCaracteristica;
            }
            const itemEVPersona = [];
            if (entrada.entrada.EntradasVendidasPersonas &&
              entrada.entrada.EntradasVendidasPersonas.length >= indice + 1) {
              itemEVPersona.push(
                entrada.entrada.EntradasVendidasPersonas[indice]
              );
            }
            entradaItem.EntradasVendidasPersonas = itemEVPersona;
            //
            entrada.entrada.PromocionEntradas.forEach((item) => {
              if (item.TEDatosId !== undefined &&
                item.TEDatosId !== null &&
                item.TEDatosId !== '' &&
                item.CuentaNominada !== '0') {
                //
                let indiceEPromocion = 0;
                for (let j = 0; j < parseInt(item.NumeroEntradas, 10); j++) {
                  const entradaIndividual = Object.assign({}, item);
                  if (entrada.entrada.CaracteristicasEntrada &&
                    entrada.entrada.CaracteristicasEntrada.length > 0) {
                    entradaIndividual.CaracteristicasEntrada =
                      entradaItem.CaracteristicasEntrada;
                  }
                  const itemEVPersona = [];
                  let TEDatosIdEncontrado = false;
                  if (item.EntradasVendidasPersonas &&
                    item.EntradasVendidasPersonas.length >=
                    indiceEPromocion + 1) {
                    itemEVPersona.push(
                      item.EntradasVendidasPersonas[indiceEPromocion]
                    );
                  }
                  entradaIndividual.EntradasVendidasPersonas =
                    itemEVPersona;
                  //
                  this.fieldsList.ListadoTiposEntradaDatos.forEach((i) => {
                    if (i.TEDatosId === entrada.entrada.TEDatosId) {
                      TEDatosIdEncontrado = true;
                      const formularioJson = JSON.parse(
                        i.TiposEntradaDatosJson
                      );
                      if (formularioJson.TipoNominacion != undefined &&
                        formularioJson.TipoNominacion === 'GRUPO') {
                        if (indice === 0) {
                          entradasArrNominativas.push(entradaIndividual);
                        }
                      } else {
                        entradasArrNominativas.push(entradaIndividual);
                      }
                    }
                  });
                  if (!TEDatosIdEncontrado) {
                    entradasArrNominativas.push(entradaIndividual);
                  }
                  indiceEPromocion++;
                }
              }
            });
            indice++;
          }
        }
      });
      this.venta.entradasArrCompleto.next(entradasArrCompleto);
      this.venta.entradasArrNominativas.next(entradasArrNominativas);
      if (entradasArrNominativas.length > 0) {
        this.modalService.open('modalDatosAdicionalesEntrada');
      }
    }
  }

  ngOnDestroy() {
    this.alive = false;
    this.usuarioElevadoService.reducir();
    if (this.getTiposEntradaDatosSub) {
      this.getTiposEntradaDatosSub.unsubscribe();
    }
    if (this.pagosFormSub) {
      this.pagosFormSub.unsubscribe();
    }
    if (this.validarTarjetaMonederoSub) {
      this.validarTarjetaMonederoSub.unsubscribe();
    }
    if (this.haSidoElevadoSub) {
      this.haSidoElevadoSub.unsubscribe();
    }
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
    let inputResultante =
      this.precioTotal -
      this.venta.carritoValue.precioPrepago -
      (this.venta.carritoValue.importeDescuento
        ? this.venta.carritoValue.importeDescuento
        : 0);
    this.pagosForm.controls['transferencia'].setValidators([
      Validators.max(inputResultante),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?'),
    ]);
    this.pagosForm.controls['pinpad'].setValidators([
      Validators.max(inputResultante),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?'),
    ]);
    this.pagosForm.controls['datafono'].setValidators([
      Validators.max(inputResultante),
      Validators.min(0),
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?'),
    ]);
  }

  ngAfterViewInit() {
    // Este código se ejecutará después de que la vista del componente se haya inicializado completamente
  }

  avisarConfiguracion(event) {
    if (this.optionsForm.get('tickets').value) {
      this.toastService.info('Venta con impresión de tickets', '', {
        toastComponent: InfoToast,
      });
    } else {
      this.toastService.info('Venta sin impresión de tickets', '', {
        toastComponent: InfoToast,
      });
    }
  }

  onChanges() {
    this.pagosFormSub = this.pagosForm.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((val) => {
        this.precioTotal = 0;

        // Iteramos sobre las entradas del carrito y calculamos el precio total
        this.venta.carritoValue.entradas.forEach((entrds) => {
          if (!entrds.entrada.noEditable) {
            this.precioTotal += Number.parseFloat(
              (
                Number.parseFloat(
                  entrds.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
                ) * entrds.cantidad
              )
                .toFixed(2)
                .replace(/,/, '.')
            );
          }
        });

        // Descontamos el importe del descuento
        this.precioTotal -= this.venta.carritoValue.importeDescuento || 0;

        // Convertimos todos los valores monetarios usando la función conversor
        const valores = {
          prepago: this.conversor(val.prepago) || 0,
          transferencia: this.conversor(val.transferencia) || 0,
          pinpad: this.conversor(val.pinpad) || 0,
          datafono: this.conversor(val.datafono) || 0,
          efectivo: this.conversor(val.efectivo) || 0,
          monedero: this.conversor(val.monedero) || 0,
          aplazado: this.conversor(val.aplazado) || 0,
        };

        // Calculamos el total del pago
        this.pagoTotal = Object.keys(valores).reduce((prev, key) => {
          return prev + valores[key];
        }, 0);

        // Calculamos la diferencia y el cambio
        this.diferencia = this.precioTotal - (this.pagoTotal - valores.efectivo);
        this.diferencia = valores.efectivo <= this.diferencia
          ? this.diferencia - valores.efectivo
          : 0;
        this.diferencia = Number.parseFloat(this.diferencia.toFixed(this.divisaDecimales));
        this.cambio = this.pagoTotal - this.precioTotal;
        this.cambio = Number.parseFloat(this.cambio.toFixed(this.divisaDecimales));

        // Seteamos otros valores relacionados
        this.saldoMonedero = this.conversor(val.saldoMonedero) || 0;

        this.pago.setPagoTotal(this.pagoTotal);
        this.pago.setPagoDesglosado(valores);
        this.pago.setCambio(this.cambio);
        this.pago.setDiferencia(this.diferencia);
        this.pago.setCodigoTarjetaMonederoValue(val.codigoMonedero);

        // Validamos el formulario
        this.pago.setFormularioCorrecto(
          this.pagosForm.status === 'VALID' && this.cambio >= 0
        );

        if (this.isAplazado()) {
          let hayCambios = false;
          let efectivoP: number = valores.efectivo;
          let pinpadP: number = valores.pinpad;
          let datafonoP: number = valores.datafono;
          let tarjetaMonederoP: number = valores.monedero;
          let aplazadoP: number = valores.aplazado;
          let transferenciaP: number = valores.transferencia;

          let inputResultante: number =
            this.precioTotal -
            this.venta.carritoValue.precioPrepago -
            datafonoP -
            pinpadP -
            tarjetaMonederoP -
            transferenciaP -
            aplazadoP;

          let aplazadoAnterior = aplazadoP;

          if (inputResultante < 0 && aplazadoP > 0) {
            if (inputResultante < -aplazadoP) {
              inputResultante = inputResultante + aplazadoP;
              aplazadoP = 0;
            } else {
              aplazadoP = aplazadoP + inputResultante - efectivoP;
              aplazadoP = aplazadoP > 0 ? aplazadoP : 0;
              inputResultante = 0;
            }
            hayCambios = true;
          } else if (inputResultante >= 0 && this.isAplazado()) {
            if (
              inputResultante <=
              this.precioTotal - this.venta.carritoValue.precioPrepago - aplazadoP
            ) {
              aplazadoP = aplazadoP + inputResultante - efectivoP;
              aplazadoP = aplazadoP > 0 ? aplazadoP : 0;
              inputResultante = 0;
            } else {
              aplazadoP =
                this.precioTotal -
                this.venta.carritoValue.precioPrepago -
                aplazadoP -
                efectivoP;
              aplazadoP = aplazadoP > 0 ? aplazadoP : 0;
              inputResultante = inputResultante - aplazadoP;
            }
            hayCambios = true;
          }
          if (hayCambios && aplazadoAnterior !== aplazadoP) {
            // console.log('change', aplazadoP);
            this.pagosForm.patchValue({
              aplazado: aplazadoP.toFixed(this.divisaDecimales).toString().replace('.', ','),
            });
          }
        }

      });

    // Actualización del campo prepago con formato adecuado
    this.pagosForm.controls['prepago'].patchValue(
      this.precioPrepago > 0
        ? this.precioPrepago.toFixed(this.divisaDecimales)
        : this.formatNumber(0, this.divisaDecimales)
    );

    // Patching adicional para el campo aplazado en caso de que sea necesario
    if (this.isAplazado()) {
      // const aplazado = this.precioTotal - this.precioPrepago > 0
      //   ? this.myCurrencyPipe.transform(
      //       this.precioTotal - this.precioPrepago,
      //       this.divisaSimboloDecimal,
      //       this.divisaDecimales,
      //       this.divisaPosicion,
      //       this.divisaSimbolo,
      //       this.divisaSimboloDerecha,
      //       this.divisaSimbSeparacionMiles
      //     )
      //   : this.myCurrencyPipe.transform(
      //       0,
      //       this.divisaSimboloDecimal,
      //       this.divisaDecimales,
      //       this.divisaPosicion,
      //       this.divisaSimbolo,
      //       this.divisaSimboloDerecha,
      //       this.divisaSimbSeparacionMiles
      //     );
      const aplazado = this.precioTotal - this.precioPrepago > 0 ?
        this.precioTotal - this.precioPrepago
        : 0;
      this.pagosForm.controls['aplazado'].patchValue(aplazado.toFixed(this.divisaDecimales).replace('.', ','));
    }
  }


  formatNumber(value: number, decimals: number): string {
    return value.toFixed(decimals).replace('.', ',');
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.pagosForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
    const inputSeleccionado =
      this.formControlService.currentFormGroupValue.elemento.getAttribute(
        'formcontrolname'
      );

    if (this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1') {
      this.pagosForm.patchValue({
        pinpad: '',
        datafono: '',
        transferencia: '',
        monedero: '',
        aplazado: '',
        efectivo: '',
      });

      if (
        this.pagosForm.controls['codigoMonedero'].value &&
        (inputSeleccionado !== 'monedero' ||
          inputSeleccionado !== 'codigoMonedero')
      ) {
        this.pagosForm.controls['codigoMonedero'].patchValue('');
      }
      if (inputSeleccionado !== 'efectivo') {
        let inputResultante =
          this.precioTotal - this.venta.carritoValue.precioPrepago;
        this.formControlService.currentFormGroupValue.form.controls[
          inputSeleccionado
        ].setValidators([Validators.max(inputResultante), Validators.min(0)]);
        this.pagosForm.controls[
          event.srcElement.getAttribute('formcontrolname')
        ].setValidators([Validators.max(inputResultante), Validators.min(0)]);
        this.formControlService.currentFormGroupValue.form.controls[
          inputSeleccionado
        ].patchValue(inputResultante.toFixed(this.divisaDecimales).replace('.', ','));
        this.pagosForm.controls[
          event.srcElement.getAttribute('formcontrolname')
        ].patchValue(inputResultante.toFixed(this.divisaDecimales).replace('.', ','));
      }
    } else {
      if (inputSeleccionado === 'pinpad') {
        if (this.pagosForm.controls['datafono'].value) {
          this.pagosForm.controls['datafono'].patchValue('');
        }
      } else if (inputSeleccionado === 'datafono') {
        if (this.pagosForm.controls['pinpad'].value) {
          this.pagosForm.controls['pinpad'].patchValue('');
        }
      }
      this.pagosForm.controls[inputSeleccionado].patchValue('');
    }
  }

  focusTarjetaValue(event: any) {
    if (this.pagosForm.controls['pinpad'].value !== this.precioTotal) {
      this.formControlService.setCurrentFormGroup(
        this.pagosForm,
        event.srcElement,
        event.srcElement.getAttribute('category')
      );
      if (this.pagosForm.controls['datafono'].value) {
        this.pagosForm.controls['datafono'].patchValue('');
      }
      if (this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1') {
        if (this.pagosForm.controls['transferencia'].value) {
          this.pagosForm.controls['transferencia'].patchValue('');
        }
        if (this.pagosForm.controls['efectivo'].value) {
          this.pagosForm.controls['efectivo'].patchValue('');
        }
        if (this.pagosForm.controls['monedero'].value) {
          this.pagosForm.controls['monedero'].patchValue('');
        }
      }
      const efectivoP: number = this.pagosForm.controls['efectivo'].value
        ? this.pagosForm.controls['efectivo'].value.replace(',', '.')
        : 0;
      const transferenciaP: number = this.pagosForm.controls['transferencia']
        .value
        ? this.pagosForm.controls['transferencia'].value.replace(',', '.')
        : 0;
      const tarjetaMonederoP: number = this.pagosForm.controls['monedero'].value
        ? this.pagosForm.controls['monedero'].value.replace(',', '.')
        : 0;
      const aplazadoP: number = this.pagosForm.controls['aplazado'].value
        ? this.pagosForm.controls['aplazado'].value.replace('€', '').value.replace(',', '.')
        : 0;
      let inputResultante =
        this.precioTotal -
        this.venta.carritoValue.precioPrepago -
        efectivoP -
        transferenciaP -
        tarjetaMonederoP; // - aplazadoP ;

      const inputSeleccionado =
        this.formControlService.currentFormGroupValue.elemento.getAttribute(
          'formcontrolname'
        );
      if (inputResultante < 0) {
        inputResultante = 0;
      }
      //
      this.formControlService.currentFormGroupValue.form.controls[
        inputSeleccionado
      ].patchValue(inputResultante.toFixed(this.divisaDecimales).replace('.', ','));
      this.formControlService.currentFormGroupValue.form.controls[
        inputSeleccionado
      ].setValidators([
        Validators.max(inputResultante + efectivoP),
        Validators.min(0),
      ]);
      this.pagosForm.controls[
        event.srcElement.getAttribute('formcontrolname')
      ].patchValue(inputResultante.toFixed(this.divisaDecimales).replace('.', ','));
      this.pagosForm.controls[
        event.srcElement.getAttribute('formcontrolname')
      ].setValidators([
        Validators.max(inputResultante + efectivoP),
        Validators.min(0),
      ]);
      //
    }
  }

  focusDatafonoValue(event: any) {
    if (this.pagosForm.controls['datafono'].value !== this.precioTotal) {
      this.formControlService.setCurrentFormGroup(
        this.pagosForm,
        event.srcElement,
        event.srcElement.getAttribute('category')
      );
      if (this.pagosForm.controls['pinpad'].value) {
        this.pagosForm.controls['pinpad'].patchValue('');
      }
      if (this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1') {
        if (this.pagosForm.controls['transferencia'].value) {
          this.pagosForm.controls['transferencia'].patchValue('');
        }
        if (this.pagosForm.controls['efectivo'].value) {
          this.pagosForm.controls['efectivo'].patchValue('');
        }
        if (this.pagosForm.controls['monedero'].value) {
          this.pagosForm.controls['monedero'].patchValue('');
        }
        if (this.pagosForm.controls['aplazado'].value) {
          this.pagosForm.controls['aplazado'].patchValue('');
        }
      }
      const efectivoP: number = this.pagosForm.controls['efectivo'].value
        ? this.pagosForm.controls['efectivo'].value.replace(',', '.')
        : 0;
      const transferenciaP: number = this.pagosForm.controls['transferencia']
        .value
        ? this.pagosForm.controls['transferencia'].value.replace(',', '.')
        : 0;
      const tarjetaMonederoP: number = this.pagosForm.controls['monedero'].value
        ? this.pagosForm.controls['monedero'].value.replace(',', '.')
        : 0;
      const aplazadoP: number = this.pagosForm.controls['aplazado'].value
        ? this.pagosForm.controls['aplazado'].value.replace('€', '').replace(',', '.')
        : 0;
      let inputResultante =
        this.precioTotal -
        this.venta.carritoValue.precioPrepago -
        efectivoP -
        transferenciaP -
        tarjetaMonederoP; // - aplazadoP;
      const inputSeleccionado =
        this.formControlService.currentFormGroupValue.elemento.getAttribute(
          'formcontrolname'
        );
      if (inputResultante < 0) {
        inputResultante = 0;
      }
      this.formControlService.currentFormGroupValue.form.controls[
        inputSeleccionado
      ].patchValue(inputResultante.toFixed(2).replace('.', ','));
      this.formControlService.currentFormGroupValue.form.controls[
        inputSeleccionado
      ].setValidators([
        Validators.max(inputResultante + efectivoP),
        Validators.min(0),
      ]);
      this.pagosForm.controls[
        event.srcElement.getAttribute('formcontrolname')
      ].patchValue(inputResultante.toFixed(2).replace('.', ','));
      this.pagosForm.controls[
        event.srcElement.getAttribute('formcontrolname')
      ].setValidators([
        Validators.max(inputResultante + efectivoP),
        Validators.min(0),
      ]);
    }
  }

  focusTransferenciaValue(event: any) {
    if (
      !this.permisosService.puedeAccionar(
        this.user.currentUserValue,
        'VentaPagoTransferencia',
        {
          toast: false,
          elevarUsuario: true,
        }
      )
    ) {
      this.haSidoElevadoSub =
        this.usuarioElevadoService.haSidoElevado$.subscribe((elevado) => {
          if (elevado) {
            this.focusValue(event);
          }
        });
    } else {
      this.focusValue(event);
    }
  }

  focusTarjetaMonederoValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.pagosForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
    if (this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1') {
      this.pagosForm.patchValue({
        pinpad: '',
        datafono: '',
        transferencia: '',
        aplazado: '',
        efectivo: '',
      });
    } else {
    }
    this.formControlService.setCurrentFormGroup(
      this.pagosForm,
      document.getElementById('MonederoInput'),
      document.getElementById('MonederoInput').getAttribute('category')
    );
  }

  private cargarTransferenciaInput(event) {
    if (this.pagosForm.controls['transferencia'].value !== this.precioTotal) {
      this.focusValue(event);
    }
  }

  conversor(precio: string) {
    if (!precio) {
      return '';
    }
    if (typeof precio !== 'string') {
      return precio;
    }
    return Number.parseFloat(precio.replace(/,/, '.'));
  }


  isSelected(element) {
    return element &&
      this.formControlService.currentFormGroupValue &&
      this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  formaDePagoInternet() {
    return this.venta.carritoValue.precioPrepago;
  }

  changeEmitirFacturaSimple() {
    this.emitirFacturaSimple = !this.emitirFacturaSimple;
    if (this.emitirFacturaSimple) {
      this.emitirAlbaran = false; // Desmarcar emitirAlbaran si emitirFacturaSimple es marcado
      this.emitirFactura = false;
    }
    this.pago.setHayQueFacturarSimpleValue(this.emitirFacturaSimple);
    this.pago.setHayQueFacturarValue(this.emitirFactura);
    this.pago.setHayQueImprimirAlbaranValue(this.emitirAlbaran);
    this.pago.setHayContactosValue(this.pedirContacto);
  }

  changeEmitirFactura() {
    this.emitirFactura = !this.emitirFactura;
    if (this.emitirFactura) {
      this.emitirFacturaSimple = false;
      this.emitirAlbaran = false;
    }
    this.pago.setHayQueFacturarValue(this.emitirFactura);
    this.pago.setHayQueFacturarSimpleValue(this.emitirFacturaSimple);
    this.pago.setHayQueImprimirAlbaranValue(this.emitirAlbaran);
    this.pago.setHayContactosValue(this.pedirContacto);
  }

  changeEmitirAlbaran() {
    this.emitirAlbaran = !this.emitirAlbaran;
    if (this.emitirAlbaran) {
      this.emitirFacturaSimple = false; // Desmarcar emitirFacturaSimple si emitirAlbaran es marcado
      this.emitirFactura = false;
    }
    this.pago.setHayQueFacturarSimpleValue(this.emitirFacturaSimple);
    this.pago.setHayQueFacturarValue(this.emitirFactura);
    this.pago.setHayQueImprimirAlbaranValue(this.emitirAlbaran);
    this.pago.setHayContactosValue(this.pedirContacto);
  }

  changePedirContacto() {
    this.pedirContacto = !this.pedirContacto;
    this.pago.setHayQueFacturarValue(this.emitirFactura);
    this.pago.setHayQueImprimirAlbaranValue(this.emitirAlbaran);
    this.pago.setHayContactosValue(this.pedirContacto);
  }

  imprimirEtiquetas() {
    this.pago.setHayQueImprimirEtiquetasValue(
      this.optionsForm.controls['etiquetas'].value
    );
  }

  imprimirEntradaConjunta() {
    this.esEntradaConjunta = !this.esEntradaConjunta;
    this.pago.setOpcionesPagoValue(this.optionsForm);
  }

  tienePermiso(accion: string) {
    const conf = {
      toast: false,
      elevarUsuario: false,
    };
    return this.permisosService.puedeAccionar(
      this.user.currentUserValue,
      accion,
      conf
    );
  }

  aplicarPromocional() {
    this.venta.carritoSubject.pipe(first()).subscribe((res1: Carrito) => {
      res1.carrito = new Carrito2(res1);
      this.pago
        .carritoAplicarCodigoPromocional(
          res1,
          this.promocionalesForm.controls['codigopromocional'].value
        )
        .pipe(first())
        .subscribe((respuesta) => {
          if (respuesta !== undefined && respuesta.DatosResult) {
            //
            // Se formatean los datos de moneda - mnieva 05-12-23
            const respuestaCurrencyOK = {
              ...respuesta.DatosResult,
              precioBase: (+respuesta.DatosResult.precioBase).toFixed(this.divisaDecimales),
              precioPrepago: (+respuesta.DatosResult.precioPrepago).toFixed(this.divisaDecimales),
              precioTotal: (+respuesta.DatosResult.precioTotal).toFixed(this.divisaDecimales),
              precioConDescuento: (+respuesta.DatosResult.precioConDescuento).toFixed(this.divisaDecimales),
            }
            //
            this.venta.setCarritoPromocionalValue(respuestaCurrencyOK, res1);
            this.promocionalesForm.controls['codigopromocional'].disable();
            this.promocionalesForm.controls[
              'aplicarcodigopromocional'
            ].disable();
          } else {
            this.toastService.error(
              '',
              respuesta.Mensajes[0].DescripcionMensaje,
              {
                toastComponent: ErrorToast,
                timeOut: 5000,
              }
            );
            this.promocionalesForm.controls['codigopromocional'].patchValue('');
          }
        });
    });
  }

  validarTarjetaMonedero() {
    if (
      this.validateCodigoTarjetaMonedero(
        this.pagosForm.controls['codigoMonedero']
      ) === null &&
      this.pagosForm.controls['codigoMonedero'].value.length !== 0
    ) {
      this.errorCodigoMonedero = '';
      this.saldoMonedero = 0;
      this.codigoTarjetaMonedero =
        this.pagosForm.controls['codigoMonedero'].value;

      this.validarTarjetaMonederoSub = this.pago
        .validarTarjetaMonedero(this.codigoTarjetaMonedero)
        .subscribe((respuesta) => {
          if (respuesta !== undefined && respuesta.DatosResult) {
            if (respuesta.DatosResult.Estado === 'ACTIVA') {
              this.cambiarColorBordeTarjetaMonedero('verde');
              this.saldoMonedero = respuesta.DatosResult.SaldoDisponible;
              this.pago.setCodigoTarjetaMonederoValue(
                this.codigoTarjetaMonedero
              );
            } else {
              this.saldoMonedero = 0;
              this.cambiarColorBordeTarjetaMonedero('naranja');
              this.toastService.info(
                'La tarjeta esta: ' + respuesta.DatosResult.Estado,
                '',
                {
                  toastComponent: InfoToast,
                }
              );
            }
            const efectivoP: number = this.pagosForm.controls['efectivo'].value
              ? this.pagosForm.controls['efectivo'].value.replace(',', '.')
              : 0;
            const pinpadP: number = this.pagosForm.controls['pinpad'].value
              ? this.pagosForm.controls['pinpad'].value.replace(',', '.')
              : 0;
            const datafonoP: number = this.pagosForm.controls['datafono'].value
              ? this.pagosForm.controls['datafono'].value.replace(',', '.')
              : 0;
            const trasferenciaP: number = this.pagosForm.controls[
              'transferencia'
            ].value
              ? this.pagosForm.controls['transferencia'].value.replace(',', '.')
              : 0;
            const aplazadoP: number = this.pagosForm.controls['aplazado'].value
              ? this.pagosForm.controls['aplazado'].value.replace('€', '').replace(',', '.')
              : 0;
            let inputResultante: number =
              this.precioTotal -
              this.venta.carritoValue.precioPrepago -
              efectivoP -
              datafonoP -
              pinpadP -
              trasferenciaP -
              aplazadoP;

            if (inputResultante < 0) {
              inputResultante = 0;
            }

            if (inputResultante > this.saldoMonedero) {
              inputResultante = this.saldoMonedero;
              this.saldoMonedero = 0;
            } else {
              this.saldoMonedero -= inputResultante;
            }
            this.pagosForm.controls['saldoMonedero'].patchValue(
              this.saldoMonedero.toFixed(this.divisaDecimales).replace('.', ',')
            );

            this.pagosForm.controls['monedero'].patchValue(
              inputResultante.toFixed(this.divisaDecimales).replace('.', ',')
            );
            this.pagosForm.controls['monedero'].setValidators([
              Validators.max(inputResultante + efectivoP),
              Validators.min(0),
            ]);
          } else {
            this.cambiarColorBordeTarjetaMonedero('rojo');
            this.saldoMonedero = 0;
            this.pagosForm.controls['saldoMonedero'].patchValue(
              this.saldoMonedero.toFixed(this.divisaDecimales).replace('.', ',')
            );
            this.pagosForm.controls['monedero'].patchValue('');
            if (!respuesta.DatosResult) {
              this.toastService.error(
                '',
                respuesta.Mensajes[0].DescripcionMensaje,
                {
                  toastComponent: ErrorToast,
                  timeOut: 5000,
                }
              );
            }
          }
        });
    } else {
      if (this.pagosForm.controls['codigoMonedero'].value.length !== 0) {
        this.errorCodigoMonedero = 'Codigo Incorrecto';
        this.cambiarColorBordeTarjetaMonedero('rojo');
      } else {
        this.cambiarColorBordeTarjetaMonedero('desactivar');
      }
      this.saldoMonedero = 0;
      this.pagosForm.controls['saldoMonedero'].patchValue(
        this.saldoMonedero.toFixed(this.divisaDecimales).replace('.', ',')
      );
      this.pagosForm.controls['monedero'].patchValue('');
    }
  }

  private validateCodigoTarjetaMonedero(control: AbstractControl) {
    if (control.value != null && control.value.length !== 0) {
      const str = control.value.toString().toUpperCase();
      const CodigoRexp = /^[a-zA-Z0-9]*$/i;
      if (!CodigoRexp.test(control.value)) {
        return 'Invalid';
      }
    }
    return null;
  }

  cambiarColorBordeTarjetaMonedero(color: string) {
    switch (color) {
      case 'naranja':
        this.TMCaducado = true;
        this.TMActivo = this.TMErroneo = false;
        break;
      case 'rojo':
        this.TMErroneo = true;
        this.TMCaducado = this.TMActivo = false;
        break;
      case 'verde':
        this.TMActivo = true;
        true;
        this.TMCaducado = this.TMErroneo = false;
        break;
      case 'desactivar':
        this.TMCaducado = this.TMActivo = this.TMErroneo = false;
        break;
    }
  }

  onBlurTarjetaMonederoValue() {
    if (
      this.pagosForm.controls['codigoMonedero'].invalid ||
      this.pagosForm.controls['codigoMonedero'].value.length === 0
    ) {
      this.cambiarColorBordeTarjetaMonedero('desactivar');
      this.saldoMonedero = 0;
      this.pagosForm.controls['saldoMonedero'].patchValue(
        this.saldoMonedero.toFixed(this.divisaDecimales).replace('.', ',')
      );
      this.pagosForm.controls['monedero'].patchValue('');
    }
  }

  transformAplazado() {
    return this.myCurrencyPipe.transform(this.precioTotal - this.precioPrepago, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimboloDerecha, this.divisaSimbSeparacionMiles)
  }

  onBlurValue(event) {
    //
    //
    if (
      this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1' &&
      this.isAplazado() &&
      this.cambio < 0 &&
      this.cambio === -(this.precioTotal - this.precioPrepago)
    ) {
      // const aplazado = this.myCurrencyPipe.transform(this.precioTotal - this.precioPrepago, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimboloDerecha, this.divisaSimbSeparacionMiles)
      this.pagosForm.controls['aplazado'].patchValue(this.transformAplazado());
    } else if (this.conf.DatosResult.AppConfigJsonPago.PagoMultiple === '1') {
      this.recalcularImportes(
        this.formControlService.currentFormGroupValue.elemento.getAttribute(
          'formcontrolname'
        )
      );
    }
  }

  comprobarEntradaTieneHorario(entrada, horarios): boolean {
    var respuesta = false;
    if (
      entrada &&
      horarios &&
      horarios.horarios &&
      horarios.horarios.length > 0
    ) {
      var idEntrada = entrada.TipoEntradaId;
      horarios.horarios.forEach((element) => {
        if (element.cantidadxentrada && element.cantidadxentrada.length > 0) {
          element.cantidadxentrada.forEach((item) => {
            if (item.entrada.TipoProducto == '1') {
              if (item.entrada.TipoEntradaId == idEntrada) {
                respuesta = true;
              }
            }
          });
        }
      });
    }
    return respuesta;
  }

  isAplazado() {
    return (
      this.venta.clienteSeleccionadoValue.Codigo > 0 &&
      (this.venta.clienteSeleccionadoValue.FP_FOP === 'DP' ||
        this.venta.clienteSeleccionadoValue.FP_FOP1 === 'DP')
    );
  }

  actualizarAplazadoVisual(valor: string) {
    this.aplazadoVisual = this.myCurrencyPipe.transform(
      valor,
      this.divisaSimboloDecimal,
      this.divisaDecimales,
      this.divisaPosicion,
      this.divisaSimbolo,
      this.divisaSimboloDerecha,
      this.divisaSimbSeparacionMiles
    );
  }

  // Formatear el valor con el símbolo de la moneda y los separadores de miles
  formatearValorMoneda(valor: string | number): string {
    if (!valor) {
      return '';
    }

    const valorNumerico = parseFloat(valor.toString().replace(/,/g, ''));
    if (isNaN(valorNumerico)) {
      return '';
    }

    // Formatear el número con separadores de miles y símbolo de moneda
    return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(valorNumerico);
  }

  recalcularImportes(inputSeleccionado: string) {
    if (this.formControlService.currentFormGroupValue.elemento)
      inputSeleccionado =
        this.formControlService.currentFormGroupValue.elemento.getAttribute(
          'formcontrolname'
        );

    if (
      this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1' &&
      this.isAplazado() &&
      this.cambio < 0 &&
      this.cambio === -(this.precioTotal - this.precioPrepago)
    ) {
      // const aplazado = this.myCurrencyPipe.transform(this.precioTotal - this.precioPrepago, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo, this.divisaSimboloDerecha, this.divisaSimbSeparacionMiles)
      this.pagosForm.controls['aplazado'].patchValue(this.transformAplazado());
    } else if (this.conf.DatosResult.AppConfigJsonPago.PagoMultiple === '1') {
      let hayCambios = false;
      // const valores: number[] = [];
      let efectivoP: number = this.pagosForm.controls['efectivo'].value
        ? +this.pagosForm.controls['efectivo'].value.replace(',', '.')
        : 0;
      let pinpadP: number =
        this.pagosForm.controls['pinpad'].value &&
          inputSeleccionado !== 'datafono'
          ? +this.pagosForm.controls['pinpad'].value.replace(',', '.')
          : 0;
      let datafonoP: number =
        this.pagosForm.controls['datafono'].value &&
          inputSeleccionado !== 'pinpad'
          ? +this.pagosForm.controls['datafono'].value.replace(',', '.')
          : 0;
      let tarjetaMonederoP: number = this.pagosForm.controls['monedero'].value
        ? +this.pagosForm.controls['monedero'].value.replace(',', '.')
        : 0;
      let aplazadoP: number = this.pagosForm.controls['aplazado'].value
        ? +this.pagosForm.controls['aplazado'].value.replace(',', '.')
        : 0;
      let transferenciaP: number = this.pagosForm.controls['transferencia']
        .value
        ? +this.pagosForm.controls['transferencia'].value.replace(',', '.')
        : 0;

      let inputResultante: number =
        this.precioTotal -
        this.venta.carritoValue.precioPrepago -
        datafonoP -
        pinpadP -
        tarjetaMonederoP -
        transferenciaP -
        aplazadoP;

      if (inputResultante < 0 && aplazadoP > 0) {
        if (inputResultante < -aplazadoP) {
          inputResultante = inputResultante + aplazadoP;
          aplazadoP = 0;
        } else {
          aplazadoP = aplazadoP + inputResultante - efectivoP;
          aplazadoP = aplazadoP > 0 ? aplazadoP : 0;
          inputResultante = 0;
        }
        hayCambios = true;
      } else if (inputResultante >= 0 && this.isAplazado()) {
        if (
          inputResultante <=
          this.precioTotal - this.venta.carritoValue.precioPrepago - aplazadoP
        ) {
          aplazadoP = aplazadoP + inputResultante - efectivoP;
          aplazadoP = aplazadoP > 0 ? aplazadoP : 0;
          inputResultante = 0;
        } else {
          aplazadoP =
            this.precioTotal -
            this.venta.carritoValue.precioPrepago -
            aplazadoP -
            efectivoP;
          aplazadoP = aplazadoP > 0 ? aplazadoP : 0;
          inputResultante = inputResultante - aplazadoP;
        }
        hayCambios = true;
      }

      if (
        inputResultante < 0 &&
        transferenciaP > 0 &&
        inputSeleccionado !== 'transferencia'
      ) {
        if (inputResultante < -transferenciaP) {
          inputResultante = inputResultante + transferenciaP;
          transferenciaP = 0;
        } else {
          transferenciaP = transferenciaP + inputResultante;
          inputResultante = 0;
        }
        hayCambios = true;
      }
      if (
        inputResultante < 0 &&
        datafonoP > 0 &&
        inputSeleccionado !== 'datafono'
      ) {
        if (inputResultante < -datafonoP) {
          inputResultante = inputResultante + datafonoP;
          datafonoP = 0;
        } else {
          datafonoP = datafonoP + inputResultante;
          inputResultante = 0;
        }
        hayCambios = true;
      }
      if (
        inputResultante < 0 &&
        pinpadP > 0 &&
        inputSeleccionado !== 'pinpad'
      ) {
        if (inputResultante < -pinpadP) {
          inputResultante = inputResultante + pinpadP;
          pinpadP = 0;
        } else {
          pinpadP = pinpadP + inputResultante;
          inputResultante = 0;
        }
        hayCambios = true;
      }

      inputResultante =
        this.precioTotal -
        this.venta.carritoValue.precioPrepago -
        (inputSeleccionado !== 'datafono' ? datafonoP : 0) -
        (inputSeleccionado !== 'pinpad' ? pinpadP : 0) -
        (inputSeleccionado !== 'monedero' ? tarjetaMonederoP : 0) -
        (inputSeleccionado !== 'transferencia' ? transferenciaP : 0) -
        (inputSeleccionado !== 'aplazado' ? aplazadoP : 0);

      if (inputSeleccionado !== 'efectivo') {
        if (
          +this.pagosForm.controls[inputSeleccionado].value.replace(',', '.') >
          inputResultante
        ) {
          switch (inputSeleccionado) {
            case 'transferencia':
              transferenciaP = inputResultante > 0 ? inputResultante : 0;
              hayCambios = true;
              break;
            case 'datafono':
              datafonoP = inputResultante > 0 ? inputResultante : 0;
              hayCambios = true;
              break;
            case 'pinpad':
              pinpadP = inputResultante > 0 ? inputResultante : 0;
              hayCambios = true;
              break;
          }
        }
      } else {
        // if (efectivoP !== inputResultante) {
        //   aplazadoP = efectivoP > aplazadoP ? 0 : aplazadoP - efectivoP;
        //   hayCambios = true;
        // }
      }
      if (hayCambios) {
        this.pagosForm.patchValue({
          pinpad: pinpadP.toFixed(this.divisaDecimales).toString().replace('.', ','),
          datafono: datafonoP.toFixed(this.divisaDecimales).toString().replace('.', ','),
          transferencia: transferenciaP.toFixed(this.divisaDecimales).toString().replace('.', ','),
          monedero: tarjetaMonederoP.toFixed(this.divisaDecimales).toString().replace('.', ','),
          aplazado: aplazadoP.toFixed(this.divisaDecimales).toString().replace('.', ','),
          efectivo: efectivoP.toFixed(this.divisaDecimales).toString().replace('.', ','),
        });
      }
    }
  }

}