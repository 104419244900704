import { Component, OnInit } from '@angular/core';
import { ButacasService } from '../../../services/butacas.service';
import { Router } from '@angular/router';
import { StepperNavigationService, VentaService } from 'src/app/modules/venta';
import { ConfigurationService, TurnoService } from 'src/app/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/app/app.configuration';
import { EspectaculosService } from '../../../services/espectaculos.service';
import { TranslateService } from 'src/app/shared/services/translate.service';

export interface EstadosButacas {
  DatosResult: [
    {
      Estados: [
        {
          ClicInternet: string;
          ClicReserva: string;
          ClicTaquilla: string;
          Color: string;
          Descripcion: string;
          EstadoButaca: string;
          IDRecinto: string;
          MostrarInternet: string;
          MostrarReserva: string;
          MostrarTaquilla: string;
          NumButaca: string;
          TipoButaca: string;
        }
      ];
    }
  ];
}

export interface EstadosButacasSesion {
  DatosResult: [
    {
      Butacas: [
        {
          Estado: string;
          IdsRelacion: [
            {
              EstadoInicial: string;
              IdRB: string;
              IdSvg: string;
              TipoBTC: string;
            }
          ];
        }
      ];
    }
  ];
}

@Component({
  selector: 'ticketing-mapa-recinto',
  templateUrl: './mapa-recinto.component.html',
  styleUrls: ['./mapa-recinto.component.scss'],
})
export class MapaRecintoComponent implements OnInit {
  estadosButacas;
  estadosButacasSesion;
  estadoBtc;
  tipoBtc;
  estadoBtcSes;
  TipoBtcSes;
  color;
  descripcion;
  leyenda = [];
  filtroLeyenda = [];
  match = false;
  constructor(
    private butacaService: ButacasService,
    private ventaService: VentaService,
    private router: Router,
    private config: AppConfiguration,
    private butacasService: ButacasService,
    private http: HttpClient,
    private espectaculoService: EspectaculosService,
    private turnoService: TurnoService,
    private stepperNavigationService: StepperNavigationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getTipoButacasPorSesion();
    this.getTipoButaca();
  }

  irAPagar() {
    
    if (!this.ventaService.carritoValue.isEmpty()) {

      this.ventaService.irPago = true;

      //manieva en entradas conjuntas
      if (this.ventaService.esEntradaCombinada()) {
        //apago el flag chequeado en el guard
        this.ventaService.carritoModificado = false;
        //si la entrada necesita completar horarios
        if (this.ventaService.esEntradaConHorariosCompletos()) { 
          //contemplar si el paso de procedencia esta habilitado
          //traspasar la responsabilidad del paso de los steps
          return this.router.navigate(['venta', 'horarios']);
          // return this.router.navigate(['venta', 'procedencia'], { state: { fromButton: true }})
          //   .then(success => {
          //     if (success) {
          //       this.stepperNavigationService.next();
          //     }
          //   });
        }
        else
          return this.router.navigate(['../venta/horarios']);
      }
      else
        return this.router.navigate(['']);

    }
  }

  getTipoButaca() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: this.translateService.getLocale() || 'es-ES',
      Aplicacion: '2',
    };
    this.http
      .post(`${this.config.getConfig('API_URL')}api/EstadosButacas`, body)
      .subscribe((data: EstadosButacas) => {       
        this.estadosButacas = data;
        this.compareData();
      });
  }

  getTipoButacasPorSesion() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: this.translateService.getLocale() || 'es-ES',
      EspectaculoId: this.espectaculoService.sesionSeleccionada.espectaculoId,
      RecintosId: '',
      RDGIds: '',
      FechaSesion: this.espectaculoService.sesionSeleccionada.fechaSesion,
      HoraInicioSesion:
        this.espectaculoService.sesionSeleccionada.horaInicioSesion,
      SesionesInternetId: this.turnoService.turnoValue.TpvId,
      Plataforma: '2',
    };
    this.http
      .post(
        `${this.config.getConfig('API_URL')}api/EstadosButacasPorSesion`,
        body
      )
      .subscribe((data: EstadosButacasSesion) => {
        this.estadosButacasSesion = data;
        this.compareData();
      });
  }

  compareData() {
    if(this.estadosButacasSesion && this.estadosButacas) {
      this.estadosButacasSesion.DatosResult.Butacas.forEach(item2 => {
        const estadoSesiones = item2.Estado;
        item2.IdsRelacion.forEach(butaca => {
          const tipoCasilla = this.estadosButacas.DatosResult.Estados.find(obj => obj.EstadoButaca === estadoSesiones && obj.TipoButaca === butaca.TipoBTC);
            if(!this.leyenda.includes(tipoCasilla) && tipoCasilla !== undefined) {
              this.leyenda.push(tipoCasilla);
            }      
        });
        
      })
      this.leyenda.forEach(item => {
        const legend = {Descripcion: item.Descripcion, Color: item.Color}
        if (!this.filtroLeyenda.some(legend => legend.Descripcion === item.Descripcion)) {
          this.filtroLeyenda.push(legend);
        }
      })
    }

 }
}
