import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import 'moment/min/locales';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, takeWhile } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfigurationService } from 'src/app/core/conf/configuration.service';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { ModalService } from 'src/app/shared/services/modal.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { EntradasService } from '../../services/entradas.service';
import { RecintosService } from '../../services/recintos.service';
import { StepperNavigationService } from '../../services/stepper-navigation.service';
import { VentaService } from '../../services/venta.service';

@Component({
  selector: 'ticketing-selector-horarios',
  templateUrl: './selector-horarios.component.html',
  styleUrls: ['./selector-horarios.component.scss'],
})
export class SelectorHorariosComponent implements OnInit, OnDestroy {
  @ViewChild('ConsultarGrupoRecintoIdModal')
  ConsultarGrupoRecintoIdModal: ElementRef;

  fecha;
  recintos = [];
  currentRecinto;
  currentRecintoIndex = 0;
  sesionSeleccionada;
  //guiaSeleccionado;
  idioma = 'es-ES';
  listadoProductos = [];
  sesionesSeleccionadas: any;
  recintoExterno: { recinto: string };
  entradas: any;
  currentEntrada = 0;
  currentCaracteristica = 0;
  currentSesionConCaracteristicaFecha;
  currentSesionConCaracteristicaHora;
  seleccionarGuia = false;
  ponmeBotonGuias = false;
  avanzado = [];
  noavanzar = false;
  entradaValida = [];
  private alive = true;
  mostarModal = false;
  franjasHorario = [];
  miSesion: any;
  constructor(
    private ventaService: VentaService,
    private modalService: ModalService,
    private configurationService: ConfigurationService,
    private navigationService: StepperNavigationService,
    private recintosService: RecintosService,
    private entradasService: EntradasService,
    private toast: ToastrService, //Yaribel 20210414  Añadimos los mensajes
    private translate: TranslateService //Yaribel 20210414 Añadimos traducciones
  ) {}

  ngOnInit() {
    const fecha = this.ventaService.fechaSeleccionadaValue == '' ? moment() : this.ventaService.fechaSeleccionadaValue;
    this.fecha = moment(fecha).format(
      'YYYY/MM/DD'
    );
    this.currentSesionConCaracteristicaFecha = moment(
      this.ventaService.fechaSeleccionadaValue
    ).format('YYYYMMDD');
    this.sesionesSeleccionadas = Object.assign(
      {},
      this.ventaService.carritoValue.horarios
    );

    this.configurationService.datosInicio
      .pipe(takeWhile(() => this.alive))
      .subscribe((conf) => (this.idioma = conf.Idioma));
    this.recintos = this.recintosService.recintosValue.map((recinto) => {
      return { ...recinto, fecha: this.fecha, sesiones: {} };
    });
    const codRecintos = this.recintos.map((recinto) => {
      return { codRecinto: recinto.Recinto };
    });
    let entradasmodificadas = [];
    this.ventaService.carritoValue.entradas.forEach((entrada) => {
      if (entrada.entrada.TipoProducto === '1') {
        if (
          entrada.entrada.CaracteristicasEntrada &&
          entrada.entrada.CaracteristicasEntrada.length > 0 &&
          this.recintosService.hayRecintosConAforoExterno(entrada.entrada)
        ) {
          entrada.entrada.CaracteristicasEntrada.forEach((item) => {
            this.listadoProductos.push(
              entrada.entrada.TipoProducto +
                ',' +
                entrada.entrada.TipoEntradaId +
                ',' +
                entrada.cantidad +
                ',,,,' +
                item.Tipo +
                ',' +
                item.Rango +
                ',' +
                item.Nivel
            );
          });
        } else if (
          entrada.entrada.CaracteristicasEntrada &&
          entrada.entrada.CaracteristicasEntrada.length > 0
        ) {
          entrada.entrada.FiltrosCaracteristicas =
            entrada.entrada.CaracteristicasEntrada;
          entrada.entrada.CaracteristicasEntrada = null;
          this.listadoProductos.push(
            entrada.entrada.TipoProducto +
              ',' +
              entrada.entrada.TipoEntradaId +
              ',' +
              entrada.cantidad
          );
        } else {
          this.listadoProductos.push(
            entrada.entrada.TipoProducto +
              ',' +
              entrada.entrada.TipoEntradaId +
              ',' +
              entrada.cantidad
          );
        }
      } else if (entrada.entrada.TipoProducto === '2') {
        if (
          entrada.entrada.CaracteristicasEntrada &&
          entrada.entrada.CaracteristicasEntrada.length > 0 &&
          this.recintosService.hayRecintosConAforoExterno(entrada.entrada)
        ) {
          entrada.entrada.CaracteristicasEntrada.forEach((item) => {
            this.listadoProductos.push(
              entrada.entrada.TipoProducto +
                ',' +
                entrada.entrada.TipoPromocionId +
                ',' +
                entrada.cantidad +
                ',,,,' +
                item.Tipo +
                ',' +
                item.Rango +
                ',' +
                item.Nivel
            );
          });
        } else if (
          entrada.entrada.CaracteristicasEntrada &&
          entrada.entrada.CaracteristicasEntrada.length > 0
        ) {
          entrada.entrada.FiltrosCaracteristicas =
            entrada.entrada.CaracteristicasEntrada;
          entrada.entrada.CaracteristicasEntrada = null;
          this.listadoProductos.push(
            entrada.entrada.TipoProducto +
              ',' +
              entrada.entrada.TipoPromocionId +
              ',' +
              entrada.cantidad
          );
        } else {
          this.listadoProductos.push(
            entrada.entrada.TipoProducto +
              ',' +
              entrada.entrada.TipoPromocionId +
              ',' +
              entrada.cantidad
          );
        }
      }
      entradasmodificadas.push(entrada);
    });
    this.ventaService.carritoValue.entradas=entradasmodificadas;   
    this.ventaService.fechaSeleccionada
      .pipe(takeWhile(() => this.alive))
      .subscribe((fecha) => {
        const fechaValue = fecha == '' ? moment() : fecha;
        this.ventaService.modificarTarifasFechaXSesiones(
          moment(fechaValue).format('YYYY/MM/DD')
        );
        this.fecha = moment(fechaValue).format('YYYY/MM/DD');
        this.entradasService
          .getSesionesEntreFechas(
            this.fecha,
            this.fecha,
            codRecintos,
            this.listadoProductos
          )
          .pipe(takeWhile(() => this.alive))
          .subscribe((res: any) => { 
            if (res.DatosSesionesEntreFechas) {
              res.DatosSesionesEntreFechas.forEach((item) => {
                if (item.RecintoId === -1) {
                  item.RecintoId = 14;
                }
              });
              this.franjasHorario = [
                ...new Set(
                  res.DatosSesionesEntreFechas.map((item) => item.FranjaHorario)
                ),
              ];
            }
            codRecintos.forEach((cod) => {
              const recinto = this.recintos.find(
                (r) => r.Recinto === cod.codRecinto
              );
              recinto.fecha = moment(fechaValue).format('YYYY/MM/DD');
              this.franjasHorario.forEach((franja) => {
                recinto.sesiones[franja] = this.generarFechas(
                  recinto,
                  franja,
                  res.DatosSesionesEntreFechas || []
                );
              });
            });            
            this.entradas = this.ventaService.carritoValue.entradas;
            this.currentRecinto = this.recintos[this.currentRecintoIndex];
            this.currentEntrada = this.findIndex();
            this.ventaService.currentRecinto =
            this.recintos[this.currentRecintoIndex];
            if (this.currentRecinto.TipoAforo === "SD") {
              
              const sesiones = this.currentRecinto.sesiones;
              let primeraSesion = null;
            
              for (const key in sesiones) {
                if (Array.isArray(sesiones[key]) && sesiones[key].length > 0) {
                  primeraSesion = sesiones[key][0]; 
                  break;
                }
              }
            
              if (primeraSesion) {
                this.comprobarGrupoRecintoId2(primeraSesion)
              }
            }
          });
      });
    this.avanzaEntradas_SinVisitante(1, true);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  findIndex = () : number => {
    let exist;
    let index = 0;
    this.entradas.forEach((item, i) => {       
      if(item.entrada.recintos && item.entrada.recintos.length > 0) {
        exist = item.entrada.recintos.find(val => val.Recinto == this.currentRecinto.Recinto);        
        if(exist){
          index = i;
        }     
      }      
    });
    return index;
  }

  generarFechasOld(recinto, franja, datosSesion) {
    const sesiones = datosSesion
      .filter((sesion) => {
        if (sesion.RecintoId === -1) {
          return (
            this.recintoExterno.recinto === recinto.Recinto &&
            sesion.FranjaHorario === franja
          );
        }
        // Control de mismo dia misma hora
        if (
          this.ventaService.carritoValue.horariosPrevios &&
          this.ventaService.carritoValue.horariosPrevios.length > 0
        ) {
          const horarioPrevio =
            this.ventaService.carritoValue.horariosPrevios.find(
              (horario) =>
                !horario.sesion.RecintosSesionId &&
                recinto.Recinto == horario.sesion.RecintoId &&
                horario.sesion.Fecha === sesion.Fecha &&
                horario.sesion.HoraInicio === sesion.HoraInicio
            );
          if (horarioPrevio) {
            sesion.Disponible =
              Number.parseInt(sesion.Disponible, 10) +
              Number.parseInt(horarioPrevio.cantidad, 10);
          }
        }
        return (
          sesion.RecintoId === Number.parseInt(recinto.Recinto, 10) &&
          sesion.FranjaHorario === franja
        );
      })
      .map((sesion) => {
        let f = moment(
          `${this.fecha} ${sesion.HoraInicio}`,
          'YYYY/MM/DD HH:mm'
        );
        if (
          recinto.TiempoExtra &&
          recinto.TiempoExtra !== null &&
          recinto.TiempoExtra !== undefined &&
          recinto.TiempoExtra !== ''
        ) {
          return {
            ...sesion,
            disabled: f.isBefore(moment().add(+recinto.TiempoExtra, 'minutes')),
          };
        }
        return { ...sesion, disabled: f.isBefore(moment()) };
      });
    const sesionesLimpias = [];
    while (sesiones.length) {
      const trocito = sesiones.splice(0, this.totalCeldasPorFila());
      if (!trocito.every((val) => val.disabled)) {
        sesionesLimpias.push(...trocito);
      }
    }
    return sesionesLimpias;
  }

  generarFechas(recinto, franja, datosSesion) {
    const sesiones = datosSesion
      .filter((sesion) => {
        if (sesion.RecintoId === -1) {
          return (
            this.recintoExterno.recinto === recinto.Recinto &&
            sesion.FranjaHorario === franja
          );
        }
        // Control de mismo dia misma hora
        if (
          this.ventaService.carritoValue.horariosPrevios &&
          this.ventaService.carritoValue.horariosPrevios.length > 0
        ) {
          const horarioPrevio =
            this.ventaService.carritoValue.horariosPrevios.find(
              (horario) =>
                !horario.sesion.RecintosSesionId &&
                recinto.Recinto == horario.sesion.RecintoId &&
                horario.sesion.Fecha === sesion.Fecha &&
                horario.sesion.HoraInicio === sesion.HoraInicio
            );
          if (horarioPrevio) {
            sesion.Disponible =
              Number.parseInt(sesion.Disponible, 10) +
              Number.parseInt(horarioPrevio.cantidad, 10);
          }
        }
        return (
          sesion.RecintoId === Number.parseInt(recinto.Recinto, 10) &&
          sesion.FranjaHorario === franja
        );
      })
      .map((sesion) => {
        let f = moment(`${this.fecha} ${sesion.HoraInicio}`, 'YYYY/MM/DD HH:mm');
  
        // Verificar si es una sesión diaria (TipoAforo === 'SD')
        if (sesion.TipoAforo === 'SD') {
          // Calcular la hora de fin + tiempo extra
          let horaFin = moment(
            `${this.fecha} ${sesion.HoraFin}`,
            'YYYY/MM/DD HH:mm'
          );
          if (
            recinto.TiempoExtra &&
            recinto.TiempoExtra !== null &&
            recinto.TiempoExtra !== undefined &&
            recinto.TiempoExtra !== ''
          ) {
            horaFin = horaFin.add(+recinto.TiempoExtra, 'minutes');
          }
  
          // Si la fecha y hora actual es antes de la hora fin + tiempo extra, la sesión está disponible
          const disabled = moment().isAfter(horaFin);
  
  
          return {
            ...sesion,
            disabled: disabled
          };
        }
  
        // Para sesiones no diarias
        if (
          recinto.TiempoExtra &&
          recinto.TiempoExtra !== null &&
          recinto.TiempoExtra !== undefined &&
          recinto.TiempoExtra !== ''
        ) {
          return {
            ...sesion,
            disabled: f.isBefore(moment().add(+recinto.TiempoExtra, 'minutes')),
          };
        }
        return { ...sesion, disabled: f.isBefore(moment()) };
      });
  
    const sesionesLimpias = [];
    while (sesiones.length) {
      const trocito = sesiones.splice(0, this.totalCeldasPorFila());
      if (!trocito.every((val) => val.disabled)) {
        sesionesLimpias.push(...trocito);
      }
    }
    return sesionesLimpias;
  }
  
  

  prev() {
    if (this.comprobarLimitePrev()) {
      return;
    }
    this.currentRecintoIndex--;
    this.currentRecinto = this.recintos[this.currentRecintoIndex];
    this.ventaService.currentRecinto = this.recintos[this.currentRecintoIndex];
    if (
      this.currentEntrada > 0 &&
      ((this.entradas[this.currentEntrada - 1].entrada.CaracteristicasEntrada &&
        this.entradas[this.currentEntrada - 1].entrada.CaracteristicasEntrada
          .length == 0) ||
        (this.entradas[this.currentEntrada - 1].entrada
          .CaracteristicasEntrada &&
          this.entradas[this.currentEntrada - 1].entrada.CaracteristicasEntrada
            .length > 0 &&
          this.currentCaracteristica == 0))
    ) {
      this.avanzaEntradas_SinVisitante(-1);
    }
  }

  comprobarLimitePrev() {
    return this.currentRecintoIndex === 0;
  }

  next() {
    if (this.comprobarLimiteNext()) {
      this.navigationService.next();
      return;
    }
    this.currentRecintoIndex++;
    this.currentRecinto = this.recintos[this.currentRecintoIndex];
    this.ventaService.currentRecinto = this.recintos[this.currentRecintoIndex];
    // Accediendo a fechaSeleccionada desde ventaService
    // Asignar la fecha de la sesión seleccionada a fechaSeleccionadaValue
    const fechaSeleccionada = this.fecha;
    this.ventaService.setFechaSeleccionadaValue(fechaSeleccionada);
    if (
      this.currentRecintoIndex >
      this.entradas[this.currentEntrada].entrada.recintos.length - 1
    ) {
      this.avanzaEntradas_SinVisitante(1);
    }
  }

  comprobarLimiteNext() {
    return this.currentRecintoIndex === this.recintos.length - 1;
  }

  nextEntrada(lCaracteristicas) {
    if (this.comprobarLimiteEntradaNext(lCaracteristicas)) {
      this.navigationService.next();
      return;
    }
    if (this.currentCaracteristica < lCaracteristicas.length - 1) {
      this.currentCaracteristica++;
    } else {
      this.currentCaracteristica = 0;
      this.avanzaEntradas_SinVisitante(1);
    }
    //Meter que elija la sesion seleccionada en this
    //if (this.sesionSeleccionada == null)
    //{
    var sesionpadre = this.ventaService.carritoValue.horarios.find(
      (horario) =>
        (horario.sesion.RecintosSesionId === this.currentRecinto.Recinto ||
          (!horario.sesion.RecintosSesionId &&
            this.currentRecinto.Recinto == horario.sesion.RecintoId)) &&
        horario.sesion.Fecha === this.currentSesionConCaracteristicaFecha &&
        horario.indiceEntrada === this.currentEntrada &&
        horario.indiceCaracteristica === this.currentCaracteristica
    );
    //horario.sesion.HoraInicio === this.currentSesionConCaracteristicaHora &&
    this.sesionSeleccionada = sesionpadre == null ? null : sesionpadre.sesion;
    //}
  }

  prevEntrada(max) {
    if (this.comprobarLimiteEntradaPrev()) {
      return;
    }
    if (this.currentCaracteristica > 0) {
      this.currentCaracteristica--;
    } else {
      this.currentCaracteristica = max - 1;
      this.avanzaEntradas_SinVisitante(-1);
    }
    //Meter que elija la sesion seleccionada en this
    //if (this.sesionSeleccionada == null)
    //{
    var sesionpadre = this.ventaService.carritoValue.horarios.find(
      (horario) =>
        (horario.sesion.RecintosSesionId === this.currentRecinto.Recinto ||
          (!horario.sesion.RecintosSesionId &&
            this.currentRecinto.Recinto == horario.sesion.RecintoId)) &&
        horario.sesion.Fecha === this.currentSesionConCaracteristicaFecha &&
        horario.indiceEntrada === this.currentEntrada &&
        horario.indiceCaracteristica === this.currentCaracteristica
    );
    //horario.sesion.HoraInicio === this.currentSesionConCaracteristicaHora &&
    this.sesionSeleccionada = sesionpadre == null ? null : sesionpadre.sesion;
    //}
  }

  comprobarLimiteEntradaPrev() {
    let respuesta = true;
    respuesta =
      (this.currentEntrada === 0 && this.currentCaracteristica === 0) ||
      (this.currentEntrada > 0 &&
        this.currentCaracteristica === 0 &&
        this.entradas[this.currentEntrada - 1].entrada.CaracteristicasEntrada
          .length === 0);
    //|| this.currentCaracteristica == 0)
    return respuesta;
  }

  comprobarLimiteEntradaNext(lCaracteristicas) {
    let respuesta = true;
    respuesta =
      this.currentCaracteristica === lCaracteristicas.length - 1 &&
      this.entradas[this.currentEntrada].entrada.CaracteristicasEntrada.length >
        0 &&
      // Hay entradas
      this.currentEntrada === this.entradas.length - 1;
    return respuesta;
  }

  sesionSobrepasaCantidadEntradasSeleccionadas(sesion: any): boolean {
    return (
      this.getTotalEntradas() > sesion.Disponible ||
      (sesion.RecintosSesionId != '0' &&
        sesion.EstaDisponibleTaquilla == 'false')
    );
  }

  async seleccionarSesion(sesion) {
    // Yaribel 20210414
    if (this.coincideHorario(sesion)) {
      this.toast.error(
        '',
        this.translate.instant('SELECCCION_HORARIO_NO_VALIDA'),
        {
          toastComponent: ErrorToast,
        }
      );
      return;
    }
  
    if (Object.keys(this.sesionesSeleccionadas).length > 0) {
      for (let i = 0; i < Object.keys(this.sesionesSeleccionadas).length; i++) {
        if (this.sesionesSeleccionadas[i].sesion.RecintoId === sesion.RecintoId) {
          this.ventaService
            .eliminarAforoSesion(this.sesionesSeleccionadas[i].sesion.RecintosSesionId)
            .subscribe();
        }
      }
    }
  
    if (sesion.disabled || this.sesionSobrepasaCantidadEntradasSeleccionadas(sesion)) {
      return;
    }
  
    if (this.sesionSeleccionada === sesion) {
      if(sesion.TipoAforo === "SD") {
        this.next();
        return;
      }
      const index = this.ventaService.carritoValue.horarios.findIndex(
        (recinto) => recinto.sesion === this.sesionSeleccionada
      );
      this.ventaService.carritoValue.horarios.splice(index, 1);
      this.sesionSeleccionada = undefined;
      return;
    }
  
    const infoSesion = {
      index: this.currentRecintoIndex,
      indiceEntrada: this.currentEntrada,
      recinto: this.currentRecinto,
      sesion: sesion,
      cantidad: this.getTotalEntradas(),
      cantidadxentrada: this.getTotalCantidadXEntradas(),
      cantidasxEntradaSinPeriodo: this.getTotalCantidadXEntradasSinPeriodos(),
      resumen: `${this.currentRecinto.NombreRecinto} - ${moment(this.fecha, 'YYYY/MM/DD')
        .locale(this.idioma)
        .format('L')} ${sesion.HoraInicio} (${this.getTotalEntradas()})`,
    };
  
    const sesionExistente = this.sesionYaExiste(infoSesion);
  
    this.sesionSeleccionada = sesion;
  
    if (sesionExistente !== -1) {
      //<<Yaribel 20210507 eliminamos la reserva de recinto actual
      this.ventaService
        .eliminarAforoSesion(
          this.ventaService.carritoValue.horarios[sesionExistente].sesion
            .RecintosSesionId
        )
        .subscribe();
  
      this.ventaService.carritoValue.horarios[sesionExistente] = infoSesion;
  
      await this.entradasService
        .recalcularPrecio(
          this.configurationService.datosTPVPathValue.pkId,
          this.listadoProductos,
          sesion.HoraInicio,
          moment(this.fecha, 'YYYY/MM/DD'),
          this.ventaService.clienteSeleccionadoValue
        )
        .toPromise()
        .then((entradas) => {
          entradas.forEach((entradaNuevoPrecio) => {
            const { recintos } = this.ventaService.carritoValue.entradas.find(
              (entradaCarrito) =>
                entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
            ).entrada;
            this.ventaService.carritoValue.entradas.find(
              (entradaCarrito) =>
                entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
            ).entrada = { ...entradaNuevoPrecio, recintos: recintos };
          });
          this.ventaService.carritoValue.recalcularTotales();
        });
  
      this.ventaService
        .reservaAforoProductos(
          this.mapeaListaentradas2ListaProductos(infoSesion.cantidasxEntradaSinPeriodo),
          infoSesion.recinto.Recinto,
          this.fechaYYYYMMDD2slash(infoSesion.sesion.Fecha, infoSesion.sesion.HoraInicio)
        )
        .pipe(
          mergeMap((res: any) => {
            if (res.DatosResult === null) {
              if (res.Mensajes[0].CodigoMensaje === '-4') {
                this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
                  timeOut: 5000,
                  positionClass: 'toast-top-right',
                });
                this.ventaService.carritoValue.horarios = [];
                return of();
              }
            }
            this.next();
          })
        )
        .subscribe();
      return;
    }
  
    this.ventaService.carritoValue.horarios.push(infoSesion);
    this.ventaService.carritoValue.fecha = this.calcularFechaMinima();
  
    await this.entradasService
      .recalcularPrecio(
        this.configurationService.datosTPVPathValue.pkId,
        this.listadoProductos,
        sesion.HoraInicio,
        moment(this.fecha, 'YYYY/MM/DD'),
        this.ventaService.clienteSeleccionadoValue
      )
      .toPromise()
      .then((entradas) => {
        entradas.forEach((entradaNuevoPrecio) => {
          const { recintos } = this.ventaService.carritoValue.entradas.find(
            (entradaCarrito) =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada;
          let entradaAnterior = this.ventaService.carritoValue.entradas.find(
            (entradaCarrito) =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada;
          this.ventaService.carritoValue.entradas.find(
            (entradaCarrito) =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada = {
            ...entradaAnterior,
            ...entradaNuevoPrecio,
            recintos: recintos,
          };
        });
        this.ventaService.carritoValue.recalcularTotales();
      });
  
    this.ventaService
      .reservaAforoProductos(
        this.mapeaListaentradas2ListaProductos(infoSesion.cantidasxEntradaSinPeriodo),
        infoSesion.recinto.Recinto,
        this.fechaYYYYMMDD2slash(infoSesion.sesion.Fecha, infoSesion.sesion.HoraInicio)
      )
      .pipe(
        mergeMap((res: any) => {
          if (res.DatosResult === null) {
            if (res.Mensajes[0].CodigoMensaje === '-4') {
              this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
                timeOut: 5000,
                positionClass: 'toast-top-right',
              });
              this.ventaService.carritoValue.horarios = [];
              return of();
            }
          }
          this.next();
        })
      )
      .subscribe();
  }
  

  async seleccionarSesion2(sesion) {
    // Yaribel 20210414
    if (this.coincideHorario(sesion)) {
      this.toast.error(
        '',
        this.translate.instant('SELECCCION_HORARIO_NO_VALIDA'),
        {
          toastComponent: ErrorToast,
        }
      );
      return;
    }

    if (Object.keys(this.sesionesSeleccionadas).length > 0) {
      for (let i = 0; i < Object.keys(this.sesionesSeleccionadas).length; i++) {
        if (
          this.sesionesSeleccionadas[i].sesion.RecintoId === sesion.RecintoId
        ) {
          this.ventaService
            .eliminarAforoSesion(
              this.sesionesSeleccionadas[i].sesion.RecintosSesionId
            )
            .subscribe();
        }
      }
    }
    if (
      sesion.disabled ||
      this.sesionSobrepasaCantidadEntradasSeleccionadas(sesion)
    ) {
      return;
    }
    if (this.sesionSeleccionada === sesion) {
      const index = this.ventaService.carritoValue.horarios.findIndex(
        (recinto) => recinto.sesion === this.sesionSeleccionada
      );
      this.ventaService.carritoValue.horarios.splice(index, 1);
      this.sesionSeleccionada = undefined;
      return;
    }
    const infoSesion = {
      index: this.currentRecintoIndex,
      indiceEntrada: this.currentEntrada,
      recinto: this.currentRecinto,
      sesion: sesion,
      cantidad: this.getTotalEntradas(),
      cantidadxentrada: this.getTotalCantidadXEntradas(),
      cantidasxEntradaSinPeriodo: this.getTotalCantidadXEntradasSinPeriodos(),
      resumen: `${this.currentRecinto.NombreRecinto} - ${moment(
        this.fecha,
        'YYYY/MM/DD'
      )
        .locale(this.idioma)
        .format('L')} ${sesion.HoraInicio} (${this.getTotalEntradas()})`,
    };
    const sesionExistente = this.sesionYaExiste(infoSesion);
    // this.sesionYaExiste2(infoSesion);
    this.sesionSeleccionada = sesion;
    if (sesionExistente !== -1) {
      //<<Yaribel 20210507 eliminamos la reserva de recinto actual
      this.ventaService
        .eliminarAforoSesion(
          this.ventaService.carritoValue.horarios[sesionExistente].sesion
            .RecintosSesionId
        )
        .subscribe();
      //Yaribel 20210507 eliminamos la reserva de recinto actual >>
      this.ventaService.carritoValue.horarios[sesionExistente] = infoSesion;
      await this.entradasService
        .recalcularPrecio(
          this.configurationService.datosTPVPathValue.pkId,
          this.listadoProductos,
          sesion.HoraInicio,
          moment(this.fecha, 'YYYY/MM/DD'),
          this.ventaService.clienteSeleccionadoValue
        )
        .toPromise()
        .then((entradas) => {
          entradas.forEach((entradaNuevoPrecio) => {
            const { recintos } = this.ventaService.carritoValue.entradas.find(
              (entradaCarrito) =>
                entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
            ).entrada;
            this.ventaService.carritoValue.entradas.find(
              (entradaCarrito) =>
                entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
            ).entrada = { ...entradaNuevoPrecio, recintos: recintos };
          });
          this.ventaService.carritoValue.recalcularTotales();
        });

      this.ventaService
        .reservaAforoProductos(
          this.mapeaListaentradas2ListaProductos(
            infoSesion.cantidasxEntradaSinPeriodo
          ),
          infoSesion.recinto.Recinto,
          this.fechaYYYYMMDD2slash(
            infoSesion.sesion.Fecha,
            infoSesion.sesion.HoraInicio
          )
        )
        .pipe(
          mergeMap((res: any) => {
            if (res.DatosResult === null) {
              if (res.Mensajes[0].CodigoMensaje === '-4') {
                this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
                  timeOut: 5000,
                  positionClass: 'toast-top-right',
                });
                this.ventaService.carritoValue.horarios = [];
                return of();
              }
            }
          })
        )
        .subscribe();
      return;
    }
    this.ventaService.carritoValue.horarios.push(infoSesion);
    this.ventaService.carritoValue.fecha = this.calcularFechaMinima();
    await this.entradasService
      .recalcularPrecio(
        this.configurationService.datosTPVPathValue.pkId,
        this.listadoProductos,
        sesion.HoraInicio,
        moment(this.fecha, 'YYYY/MM/DD'),
        this.ventaService.clienteSeleccionadoValue
      )
      .toPromise()
      .then((entradas) => {
        entradas.forEach((entradaNuevoPrecio) => {
          const { recintos } = this.ventaService.carritoValue.entradas.find(
            (entradaCarrito) =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada;
          let entradaAnterior = this.ventaService.carritoValue.entradas.find(
            (entradaCarrito) =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada;
          this.ventaService.carritoValue.entradas.find(
            (entradaCarrito) =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada = {
            ...entradaAnterior,
            ...entradaNuevoPrecio,
            recintos: recintos,
          };
        });
        this.ventaService.carritoValue.recalcularTotales();
      });
    //Cambiar x ReservaAforoProductos

    this.ventaService
      .reservaAforoProductos(
        this.mapeaListaentradas2ListaProductos(
          infoSesion.cantidasxEntradaSinPeriodo
        ),
        infoSesion.recinto.Recinto,
        this.fechaYYYYMMDD2slash(
          infoSesion.sesion.Fecha,
          infoSesion.sesion.HoraInicio
        )
      )
      .pipe(
        mergeMap((res: any) => {
          if (res.DatosResult === null) {
            if (res.Mensajes[0].CodigoMensaje === '-4') {
              this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
                timeOut: 5000,
                positionClass: 'toast-top-right',
              });
              this.ventaService.carritoValue.horarios = [];
              return of();
            }
          }
        })
      )
      .subscribe();
  }

  async seleccionarSesionConCaracteristicas(
    sesion,
    caracteristica,
    lCaracteristicas,
    entrada,
    guia: any = undefined
  ) {
    if (Object.keys(this.sesionesSeleccionadas).length > 0) {
      for (let i = 0; i < Object.keys(this.sesionesSeleccionadas).length; i++) {
        if (
          this.sesionesSeleccionadas[i].sesion.RecintoId === sesion.RecintoId
        ) {
          this.ventaService
            .eliminarAforoSesion(
              this.sesionesSeleccionadas[i].sesion.RecintosSesionId
            )
            .subscribe();
        }
      }
    }
    if (
      sesion.disabled ||
      this.sesionSobrepasaCantidadEntradasSeleccionadas(sesion)
    ) {
      return;
    }
    /*if (this.sesionSeleccionada === sesion && guia == null) {
      const index = this.ventaService.carritoValue.horarios.findIndex(
        recinto => recinto.sesion === this.sesionSeleccionada
      );
      this.ventaService.carritoValue.horarios.splice(index, 1);
      this.sesionSeleccionada = undefined;
      return;
    }*/
    //this.guiaSeleccionado = guia;
    const infoSesion = {
      index: this.currentRecintoIndex,
      indiceEntrada: this.currentEntrada,
      indiceCaracteristica: this.currentCaracteristica,
      recinto: this.currentRecinto,
      sesion: sesion,
      cantidad: this.getTotalEntradasCaracteristica(entrada),
      caracteristica: caracteristica,
      resumen: `${this.currentRecinto.NombreRecinto} - ${moment(
        this.fecha,
        'YYYY/MM/DD'
      )
        .locale(this.idioma)
        .format('L')} ${
        sesion.HoraInicio
      } (${this.getTotalEntradasCaracteristica(entrada)})`,
    };
    const sesionExistente = this.sesionCaracteristicaYaExiste(infoSesion);
    // this.sesionYaExiste2(infoSesion);
    this.sesionSeleccionada = sesion;
    if (sesionExistente !== -1) {
      //<<Yaribel 20210507 eliminamos la reserva de recinto actual
      this.ventaService
        .eliminarAforoSesion(
          this.ventaService.carritoValue.horarios[sesionExistente].sesion
            .RecintosSesionId
        )
        .subscribe();
      //Yaribel 20210507 eliminamos la reserva de recinto actual >>
      this.ventaService.carritoValue.horarios[sesionExistente] = infoSesion;
      await this.entradasService
        .recalcularPrecio(
          this.configurationService.datosTPVPathValue.pkId,
          this.listadoProductos,
          sesion.HoraInicio,
          moment(this.fecha, 'YYYY/MM/DD'),
          this.ventaService.clienteSeleccionadoValue
        )
        .toPromise()
        .then((entradas) => {
          entradas.forEach((entradaNuevoPrecio) => {
            const { recintos } = this.ventaService.carritoValue.entradas.find(
              (entradaCarrito) =>
                entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
            ).entrada;
            this.ventaService.carritoValue.entradas.find(
              (entradaCarrito) =>
                entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
            ).entrada = { ...entradaNuevoPrecio, recintos: recintos };
          });
          this.ventaService.carritoValue.recalcularTotales();
        });
      //JS 20200811 Mantenemos el metodo API ya que no estamos pasando productos
      this.ventaService
        .reservaAforo(
          sesion.RecintosSesionId,
          this.getTotalEntradasCaracteristica(entrada)
        )
        .subscribe();
      if (
        this.sesionSeleccionada.Guias == null ||
        this.sesionSeleccionada.Guias.length === 0 ||
        !this.seleccionarGuia
      ) {
        this.currentSesionConCaracteristicaFecha =
          this.sesionSeleccionada.Fecha;
        this.currentSesionConCaracteristicaHora =
          this.sesionSeleccionada.HoraInicio;
        this.nextEntrada(lCaracteristicas);
      }
      return;
    }
    this.ventaService.carritoValue.horarios.push(infoSesion);
    this.ventaService.carritoValue.fecha = this.calcularFechaMinima();
    await this.entradasService
      .recalcularPrecio(
        this.configurationService.datosTPVPathValue.pkId,
        this.listadoProductos,
        sesion.HoraInicio,
        moment(this.fecha, 'YYYY/MM/DD'),
        this.ventaService.clienteSeleccionadoValue
      )
      .toPromise()
      .then((entradas) => {
        entradas.forEach((entradaNuevoPrecio) => {
          const { recintos } = this.ventaService.carritoValue.entradas.find(
            (entradaCarrito) =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada;
          this.ventaService.carritoValue.entradas.find(
            (entradaCarrito) =>
              entradaCarrito.entrada.idTarifa === entradaNuevoPrecio.idTarifa
          ).entrada = { ...entradaNuevoPrecio, recintos: recintos };
        });
        this.ventaService.carritoValue.recalcularTotales();
      });
    //JS 20200811 Mantenemos el metodo API ya que no estamos pasando productos
    this.ventaService
      .reservaAforo(
        sesion.RecintosSesionId,
        this.getTotalEntradasCaracteristica(entrada)
      )
      .subscribe();
    if (
      this.sesionSeleccionada.Guias == null ||
      this.sesionSeleccionada.Guias.length === 0 ||
      !this.seleccionarGuia
    ) {
      this.currentSesionConCaracteristicaFecha = this.sesionSeleccionada.Fecha;
      this.currentSesionConCaracteristicaHora =
        this.sesionSeleccionada.HoraInicio;
      this.nextEntrada(lCaracteristicas);
    }
  }

  async seleccionarGuiaConCaracteristicas(
    sesion,
    caracteristica,
    lCaracteristicas,
    entrada,
    guia: any = undefined
  ) {
    this.guardaGuiaEnCaracteristica(caracteristica, guia);
    this.guardaIdxEntradaenCaracteristica(caracteristica, entrada);
    var sesionpadre = this.ventaService.carritoValue.horarios.find(
      (horario) =>
        (horario.sesion.RecintosSesionId === sesion.RecintosSesionId ||
          (!horario.sesion.RecintosSesionId &&
            sesion.RecintoId == horario.sesion.RecintoId)) &&
        horario.sesion.Fecha === sesion.Fecha &&
        horario.sesion.HoraInicio === sesion.HoraInicio &&
        horario.indiceEntrada === entrada &&
        horario.caracteristica === caracteristica
    );
    Object.defineProperty(sesionpadre, 'GuiaSeleccionado', {
      value: guia,
      //writable: false
    });
    sesionpadre.resumen = `${sesionpadre.recinto.NombreRecinto} - ${moment(
      sesion.Fecha,
      'YYYY/MM/DD'
    )
      .locale(this.idioma)
      .format('L')} ${sesion.HoraInicio} (${sesionpadre.cantidad}) ${
      guia.NombreGuia
    }`;
    this.ventaService.carritoValue.recalcularTotales();
    this.currentSesionConCaracteristicaFecha = sesion.Fecha;
    this.currentSesionConCaracteristicaHora = sesion.HoraInicio;
    this.nextEntrada(lCaracteristicas);
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  sesionYaExiste(infoSesion) {
    return this.ventaService.carritoValue.horarios.findIndex(
      (horario) => horario.index === infoSesion.index
    );
  }
  sesionCaracteristicaYaExiste(infoSesion) {
    return this.ventaService.carritoValue.horarios.findIndex(
      (horario) =>
        horario.index === infoSesion.index &&
        horario.indiceEntrada === infoSesion.indiceEntrada &&
        horario.indiceCaracteristica === infoSesion.indiceCaracteristica
    );
  }

  /**
   * Verifica si una sesión coincide con algún horario ya presente en el carrito,
   * siguiendo las reglas de comparación de `tipoAforo` y horarios.
   * 
   * Reglas de coincidencia:
   * - Si `tipoAforo` de la sesión actual es "SD" y el `tipoAforo` de la sesión en el carrito es "SF" (o viceversa),
   *   la función devuelve `false`.
   * - Si ambos `tipoAforo` son "SD" o ambos son "SF", se compara el horario de la sesión actual con el horario en el carrito.
   * - Devuelve `true` si ambos `tipoAforo` coinciden y hay una coincidencia horaria.
   * - Devuelve `false` si no se encuentra ninguna coincidencia en tipo de aforo o en horario.
   * 
   * @param {Object} sesion - La sesión actual con la que se quiere verificar coincidencias.
   * @returns {boolean} - `true` si hay coincidencia de tipoAforo y horario; `false` en caso contrario.
   */
  coincideHorario(sesion) {
    const horarioInicio = moment(`${sesion.Fecha} ${sesion.HoraInicio}`, 'YYYY/MM/DD HH:mm');
    const horarioFin = moment(`${sesion.Fecha} ${sesion.HoraFin}`, 'YYYY/MM/DD HH:mm');
  
    let sfConflictCount = 0;
    let sdConflictCount = 0;
  
    for (let i = 0; i < this.ventaService.carritoValue.horarios.length; i++) {
      const carritoSesion = this.ventaService.carritoValue.horarios[i].sesion;
      
      // Comparar Recintos
      if (carritoSesion.RecintoId === sesion.RecintoId) {
        continue; // Si los recintos son diferentes, continuar sin verificar conflictos
      }
  
      const carritoHoraInicio = moment(`${carritoSesion.Fecha} ${carritoSesion.HoraInicio}`, 'YYYY/MM/DD HH:mm');
      const carritoHoraFin = moment(`${carritoSesion.Fecha} ${carritoSesion.HoraFin}`, 'YYYY/MM/DD HH:mm');
  
      // Verificar compatibilidad de "SF" y "SD"
      if ((sesion.TipoAforo == "SD" && carritoSesion.TipoAforo == "SF") ||
          (sesion.TipoAforo == "SF" && carritoSesion.TipoAforo == "SD")) {
        continue; // Continuar sin conflicto cuando hay mezcla de "SF" y "SD"
      }
  
      // Verificar conflictos cuando ambos son "SF"
      if (sesion.TipoAforo == "SF" && carritoSesion.TipoAforo == "SF" &&
          (
            carritoHoraInicio.isSame(horarioInicio) ||
            carritoHoraInicio.isSame(horarioFin) ||
            carritoHoraFin.isSame(horarioInicio) ||
            carritoHoraFin.isSame(horarioFin) ||
            carritoHoraInicio.isBetween(horarioInicio, horarioFin) ||
            carritoHoraFin.isBetween(horarioInicio, horarioFin) ||
            horarioInicio.isBetween(carritoHoraInicio, carritoHoraFin) ||
            horarioFin.isBetween(carritoHoraInicio, carritoHoraFin)
          )) {
        sfConflictCount++;
      }
  
      // Verificar conflictos cuando ambos son "SD"
      if (sesion.TipoAforo == "SD" && carritoSesion.TipoAforo == "SD" &&
          (
            carritoHoraInicio.isSame(horarioInicio) ||
            carritoHoraInicio.isSame(horarioFin) ||
            carritoHoraFin.isSame(horarioInicio) ||
            carritoHoraFin.isSame(horarioFin) ||
            carritoHoraInicio.isBetween(horarioInicio, horarioFin) ||
            carritoHoraFin.isBetween(horarioInicio, horarioFin) ||
            horarioInicio.isBetween(carritoHoraInicio, carritoHoraFin) ||
            horarioFin.isBetween(carritoHoraInicio, carritoHoraFin)
          )) {
        sdConflictCount++;
      }
    }
  
    // Solo retornar verdadero si hay conflictos en el mismo tipo de aforo "SF" o "SD"
    return sfConflictCount > 0 || sdConflictCount > 0;
  }
  
  
  

  actualizarAgrupadas(sesion) {
    let IndexSesionGrupoRecinto = [];
    // SesionGrupoRecinto = this.ventaService.carritoValue.horarios.
    for (let i = 0; i < this.ventaService.carritoValue.horarios.length; i++) {
      if (
        this.ventaService.carritoValue.horarios[i].sesion.GrupoRecintoId ===
          this.miSesion.GrupoRecintoId &&
        this.ventaService.carritoValue.horarios[i].sesion.RecintoId !==
          this.miSesion.RecintoId
      ) {
        IndexSesionGrupoRecinto.push(i);
      }
    }

    IndexSesionGrupoRecinto.forEach((element) => {
      this.ventaService.carritoValue.horarios.splice(element, 1);
    });

    this.seleccionarSesion(this.miSesion);
  }

  comprobarGrupoRecintoId(sesion) {
    let encontrado = false;
    for (let i = 0; i < this.ventaService.carritoValue.horarios.length; i++) {
      if (
        sesion.GrupoRecintoId !== '' &&
        sesion.GrupoRecintoId !== '0' &&
        this.ventaService.carritoValue.horarios[i].sesion.GrupoRecintoId ===
          sesion.GrupoRecintoId &&
        sesion.RecintoId !==
          this.ventaService.carritoValue.horarios[i].sesion.RecintoId
      ) {
        i = this.ventaService.carritoValue.horarios.length;
        encontrado = true;
      }
    }
    if (encontrado) {
      this.miSesion = sesion;
      this.openModal('confirmarGestion');
    } else {
      this.seleccionarSesion(sesion);
    }
  }

  comprobarGrupoRecintoId2(sesion) {
    let encontrado = false;
    for (let i = 0; i < this.ventaService.carritoValue.horarios.length; i++) {
      if (
        sesion.GrupoRecintoId !== '' &&
        sesion.GrupoRecintoId !== '0' &&
        this.ventaService.carritoValue.horarios[i].sesion.GrupoRecintoId ===
          sesion.GrupoRecintoId &&
        sesion.RecintoId !==
          this.ventaService.carritoValue.horarios[i].sesion.RecintoId
      ) {
        i = this.ventaService.carritoValue.horarios.length;
        encontrado = true;
      }
    }
    if (encontrado) {
      this.miSesion = sesion;
      this.openModal('confirmarGestion');
    } else {
      this.seleccionarSesion2(sesion);
    }
  }

  // Yaribel 20210414 Añadimos comprobacion de Coincidencia de horarios y comprobacion para GrupoRecintoId para seleccionar solo uno por grupo Recinto >>

  /*   sesionYaExiste2(sesion) {
    // return this.ventaService.carritoValue.horarios.find( sesion => )
  } */

  getFechaSeleccionada() {
    if(this.ventaService.fechaSeleccionadaValue ===  '') {
      const fechaValue = this.ventaService.fechaSeleccionadaValue == '' ? moment() : this.ventaService.fechaSeleccionadaValue;
      return moment(fechaValue)
        .locale(this.idioma)
        .format('L');
    }
    if (this.ventaService.fechaSeleccionadaValue) {
      if (
        this.fecha !==
        moment(this.ventaService.fechaSeleccionadaValue).format('YYYY/MM/DD')
      ) {
        this.ngOnInit();
      }
      return moment(this.ventaService.fechaSeleccionadaValue)
        .locale(this.idioma)
        .format('L');
    }
  }

  getTotalEntradasCaracteristica(entradaC) {
    let cantidad = 0;
    this.currentRecinto.entradas.forEach((entrada) => {
      this.ventaService.carritoValue.entradas.find((linea) => {
        if (
          linea.entrada.TipoProducto === '1' &&
          entradaC.TipoProducto === '1' &&
          entradaC.TipoEntradaId === linea.entrada.TipoEntradaId &&
          (!linea.entrada.Caracteristicas ||
            linea.entrada.Caracteristicas.NumPeriodos == '')
        ) {
          cantidad = 1;
        } else if (
          linea.entrada.TipoProducto === '2' &&
          entradaC.TipoProducto === '2' &&
          entradaC.TipoPromocionId === linea.entrada.TipoPromocionId
        ) {
          return linea.entrada.PromocionEntradas.find((item, index) => {
            cantidad = item.NumeroEntradas;
          });
        }
      });
    });
    return cantidad;
  }

  getTotalEntradas() {
    let entradasConRecinto = [];
    let cantidadEntradas = 0;
    let cantpromo = 0;
    let recitnos = [...new Set(this.currentRecinto.entradas)];
    entradasConRecinto = this.getTotalCantidadXEntradasSinPeriodos();
    entradasConRecinto.forEach((eRecinto) => {
      if (eRecinto) {
        if (eRecinto.entrada.PromocionEntradas) {
          eRecinto.entrada.PromocionEntradas.forEach((entrada) => {
            recitnos.forEach((recinto) => {
              if (recinto === entrada.TipoEntradaId) {
                cantpromo += entrada.NumeroEntradas * eRecinto.cantidad;
                return;
              }
            });
          });
          return;
        }
        cantidadEntradas += eRecinto.cantidad;
      }
    });
    if (cantpromo) cantidadEntradas = cantidadEntradas + cantpromo;
    return cantidadEntradas;
  }

  //<< Yaribel 20210112 Creamos funcion para obtener las entradas por recinto sin numero de periodos
  //como es la misma que getcantidad de entradas simplificamos el codigo
  getTotalCantidadXEntradasSinPeriodos() {
    let entradasConRecinto = [];
    let cantidad = '';
    this.currentRecinto.entradas.forEach((entrada) => {
      let entradas = this.ventaService.carritoValue.entradas.filter((linea) => {
        if (
          linea.entrada.TipoProducto === '1' &&
          (!linea.entrada.Caracteristicas ||
            linea.entrada.Caracteristicas.NumPeriodos == '' ||
            linea.entrada.Caracteristicas.NumPeriodos == '0')
        ) {
          cantidad = 'cantidad';
          return linea.entrada.TipoEntradaId == entrada;
        } else if (linea.entrada.TipoProducto === '2') {
          return linea.entrada.PromocionEntradas.find((item, index) => {
            cantidad = `entrada.PromocionEntradas[${index}].NumeroEntradas`;
            return item.TipoEntradaId == entrada;
          });
        }
      });

      entradas.forEach((element) => {
        entradasConRecinto.push(element);
      });
    });
    entradasConRecinto = this.removeDuplicates(entradasConRecinto, 'entrada');
    return entradasConRecinto;
  }
  // Yaribel 20210112 >>

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  getTotalCantidadXEntradas() {
    let entradasXrecinto = [];
    let reservaAforoPeriodos = false;
    this.currentRecinto.entradas.forEach((entrada) => {
      let entradas = this.ventaService.carritoValue.entradas.filter((linea) => {
        if (linea.entrada.TipoProducto === '1') {
          if (
            linea.entrada.Caracteristicas &&
            linea.entrada.Caracteristicas.NumPeriodos != ''
          ) {
            reservaAforoPeriodos = true;
          }
          return linea.entrada.TipoEntradaId == entrada;
        } else if (linea.entrada.TipoProducto === '2') {
          return linea.entrada.PromocionEntradas.find((item, index) => {
            reservaAforoPeriodos = true;
            return item.TipoEntradaId == entrada;
          });
        }
      });

      entradas.forEach((element) => {
        entradasXrecinto.push(element);
      });
    });
    entradasXrecinto = this.removeDuplicates(entradasXrecinto, 'entrada');
    return reservaAforoPeriodos ? entradasXrecinto : [];
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  isSelected(sesion) {
    if(sesion.TipoAforo == 'SD') {
      return true;
    }
    return this.ventaService.carritoValue.horarios.find(
      (horario) =>
        (horario.sesion.RecintosSesionId === sesion.RecintosSesionId ||
          (!horario.sesion.RecintosSesionId &&
            sesion.RecintoId == horario.sesion.RecintoId)) &&
        horario.sesion.Fecha === sesion.Fecha &&
        horario.sesion.HoraInicio === sesion.HoraInicio
    );
  }

  isCaracteristicaSelected(sesion, caracteristica, indiceEntrada) {
    return this.ventaService.carritoValue.horarios.find(
      (horario) =>
        (horario.sesion.RecintosSesionId === sesion.RecintosSesionId ||
          (!horario.sesion.RecintosSesionId &&
            sesion.RecintoId == horario.sesion.RecintoId)) &&
        horario.sesion.Fecha === sesion.Fecha &&
        horario.sesion.HoraInicio === sesion.HoraInicio &&
        horario.indiceEntrada === indiceEntrada &&
        horario.caracteristica === caracteristica
    );
  }

  isGuiaConCaracteristicaSelected(sesion, guia, caracteristica, indiceEntrada) {
    //return this.sesionSeleccionada != null && this.sesionSeleccionada.GuiaSeleccionado != null && this.sesionSeleccionada.GuiaSeleccionado == guia;
    return this.ventaService.carritoValue.horarios.find(
      (horario) =>
        (horario.sesion.RecintosSesionId === sesion.RecintosSesionId ||
          (!horario.sesion.RecintosSesionId &&
            sesion.RecintoId == horario.sesion.RecintoId)) &&
        horario.sesion.Fecha === sesion.Fecha &&
        horario.sesion.HoraInicio === sesion.HoraInicio &&
        horario.indiceEntrada === indiceEntrada &&
        horario.caracteristica === caracteristica &&
        horario.GuiaSeleccionado == guia
    );
  }

  totalCeldasPorFila() {
    const totalWidth = document.querySelector('.col-seleccion').clientWidth;
    const cellWidth = 64;
    return Math.round(totalWidth / cellWidth);
  }

  estaLaFilaDeshabilitada(celdas: any[]) {
    celdas.forEach((celda) => {
      if (celda.disabled) {
        return false;
      }
    });
    return true;
  }

  calcularFechaMinima() {
    const fechas = this.ventaService.carritoValue.horarios.map(
      (espectaculo) => {
        return moment(
          `${espectaculo.recinto.fecha} ${espectaculo.sesion.HoraInicio}`,
          'YYYY/MM/DD HH:mm:ss'
        );
      }
    );
    return moment.min(fechas).locale(this.idioma).format('YYYY/MM/DD HH:mm:ss');
  }

  mostrarSelectorEntradaConSesionxEntrada(idxEntrada, entrada) {
    //Cabecera de la entrada
    var respuesta = true;
    //;
    respuesta = idxEntrada == this.currentEntrada; // || this.mostrarSelectorSesionXEntrada(entrada);
    return respuesta;
  }

  mostrarSelectorSesionXEntrada(entrada) {
    let encontrado = false;
    if (entrada.entrada.TipoProducto === '1') {
      encontrado = this.currentRecinto.entradas.includes(
        entrada.entrada.TipoEntradaId
      );
    } else if (entrada.entrada.TipoProducto === '2') {
      entrada.entrada.PromocionEntradas.forEach((item) => {
        if (this.currentRecinto.entradas.includes(item.TipoEntradaId)) {
          encontrado = true;
        }
      });
    }
    return encontrado;
  }
  mostrarSelectorSesionXCaracteristica(idCaracteristica) {
    var respuesta = true;
    respuesta = idCaracteristica == this.currentCaracteristica;
    return respuesta;
  }

  mostrarProfe(sesion, caracteristica) {
    let mostrar = false;
    mostrar = false;
    if (this.sesionSeleccionada != null) {
      if (
        sesion.Comentarios == caracteristica.Tipo &&
        this.sesionSeleccionada == sesion &&
        this.seleccionarGuia
      ) {
        mostrar = true;
      }
    }
    return mostrar;
  }

  avanzarEntrada(recinto, maximo, entrada) {
    //;
    if (
      (!recinto ||
        recinto.length == 0 ||
        (recinto.length > 0 && recinto[0].ControlaAforo == '0')) &&
      !this.avanzado.includes(this.currentEntrada) &&
      this.currentEntrada < maximo - 1 &&
      !this.noavanzar
    ) {
      this.avanzado.push(this.currentEntrada);
      this.avanzaEntradas_SinVisitante(1);
    } else {
      this.noavanzar = true;
    }
  }

  verGuia(entrada) {
    return (
      entrada.NombrePantalla.includes('CLASE') &&
      !entrada.NombrePantalla.includes('CURS')
    );
  }

  changeSeleccionarGuia() {
    this.seleccionarGuia = !this.seleccionarGuia;
  }

  mapeaListaentradas2ListaProductos(listaentradas): string[] {
    let respuesta: string[] = [];
    listaentradas.forEach((element) => {
      let idProducto = '';
      if (element.entrada.TipoProducto == '2') {
        idProducto =
          '2,' +
          element.entrada.TipoPromocionId +
          ',' +
          element.cantidad.toString();
      } else if (element.entrada.TipoProducto == '3') {
        idProducto =
          '3,' +
          element.entrada.TipoAbonadoId +
          ',' +
          element.cantidad.toString();
      } else {
        idProducto =
          '1,' +
          element.entrada.TipoEntradaId +
          ',' +
          element.cantidad.toString();
      }
      respuesta.push(idProducto);
    });
    //if (respuesta.length > 0) respuesta = respuesta.slice(0, -1);
    return respuesta;
  }

  fechaYYYYMMDD2slash(fecha, hora) {
    let respuesta = '';
    if (fecha && fecha.length == 8) {
      respuesta =
        fecha.substring(0, 4) +
        '/' +
        fecha.substring(4, 6) +
        '/' +
        fecha.substring(6);
    }
    if (hora && hora.length == 5) {
      respuesta = respuesta + ' ' + hora + ':00';
    } else if (hora && hora.length == 8) {
      respuesta = respuesta + ' ' + hora;
    } else {
      respuesta = respuesta + ' ' + '23:59:00';
    }
    return respuesta;
  }

  compruebaValorEnArrayRecintos(array, valor): boolean {
    var resultado: boolean = false;
    array.forEach((element) => {
      if (element.Recinto == valor) {
        resultado = true;
      }
    });
    return resultado;
  }
  avanzaEntradas_SinVisitante(direccion, onInit = false) {
    if (!this.entradas) {
      if (!onInit) {
        if (direccion == 1) {
          this.currentEntrada++;
        } else {
          this.currentEntrada--;
        }
      }
    } else {
      const indices = [];
      if (direccion == 1) {
        for (
          let index = this.currentEntrada;
          index < this.entradas.length;
          index++
        ) {
          indices.push(index);
        }
        for (let index = 0; index <= this.currentEntrada; index++) {
          indices.push(index);
        }
      } else {
        for (let index = this.currentEntrada; index >= 0; index--) {
          indices.push(index);
        }
        for (
          let index = this.entradas.length - 1;
          index >= this.currentEntrada;
          index--
        ) {
          indices.push(index);
        }
      }
      let index = 0;
      do {
        index++;
      } while (
        ((this.entradas[indices[index]].entrada.CuentaVisitante != '1' &&
          this.entradas[indices[index]].entrada.PromocionEntradas.some(
            (item) => item.CuentaVisitante != '1'
          )) ||
          !this.entradas[indices[index]].entrada.recintos ||
          !this.entradas[indices[index]].entrada.recintos.some(
            (item) => item.Recinto == this.currentRecinto.Recinto
          )) &&
        index < indices.length
      );
      //^^^Probamos a saltar tambien si el Recinto no es el actual
      this.currentEntrada = indices[index];
      //Yaribel 20210205>>
    }
  }
  private guardaGuiaEnCaracteristica(caracteristica, Guia): void {
    caracteristica.Profesor = Guia ? Guia.iGuiaId : '';
  }

  private guardaIdxEntradaenCaracteristica(caracteristica, idxEntrada): void {
    caracteristica.idxEntrada = idxEntrada ? idxEntrada : '';
  }

  esReservaAgrupada() {
    if (this.ventaService.carritoValue.reservaAgrupada == '1') {
      return true;
    }
    return false;
  }

  getControlaGrupo(ControlaGrupo) {
    let res = '';
    if (ControlaGrupo && +ControlaGrupo > 0) {
      res = '-G' + (+ControlaGrupo - 1);
    }
    return res;
  }
}
