export { };

declare global {
    interface String {
        numerize(): number;
        convertToSlug(): string;
        customSeparator(): number;
    }
}

String.prototype.numerize = function (toFixed: number | null = null): number {
    let value = parseFloat(this);
    value = isNaN(value) || !isFinite(value) ? 0 : value;
    return toFixed ? parseFloat(value.toFixed(toFixed)) : value;
};

String.prototype.convertToSlug = function (): string {
    let str = this;
    if (!str) {
        return '';
    }

    return str.replace(/ +/g, '-').toLowerCase();
};

String.prototype.customSeparator = function (): number {

    let str = this;
    if (!str) {
        return 0;
    }
    return str.replace(',', '.');
}