import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FormatService {
  constructor() {}

  static addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');

    const isMinus = number.split('')[0] === '-' ? true : false;
    if (isMinus) {
      const holder = number.split('');
      number = '';
      for (let i = 1; i < holder.length; ++i) {
        number += holder[i];
      }
    }

    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        if (isMinus) {
          return '-' + newInteger;
        } else {
          return newInteger;
        }
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        if (isMinus) {
          return '-' + newInteger;
        } else {
          return newInteger;
        }
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          if (newInteger.length !== 0) {
            newInteger += '.';
          }
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
      }
      newInteger += decimal;
      if (isMinus) {
        return '-' + newInteger;
      } else {
        return newInteger;
      }
    }
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    if (isMinus) {
      return '-' + number + d;
    } else {
      return number + d;
    }
  }

  static formatFecha2(fecha, format) {
    return moment(fecha, format).format('DD/MM/YYYY HH:mm');
  }

  static formatFecha(fecha) {
    const splitFecha = fecha.split('/');
    if (splitFecha[0].length === 4 && splitFecha.length === 3) {
      return splitFecha[2] + '/' + splitFecha[1] + '/' + splitFecha[0];
    }
    return fecha;
  }

  static formatHora(hora) {
    const splitHora = hora.split(':');
    if (splitHora.length === 3) {
      return splitHora[0] + ':' + splitHora[1] + ' h';
    }
    return hora + ' h';
  }

  static calculaHora(fecha) {
    const hora = fecha.split(' ')[1];
    const [h, m, s] = hora.split(':');
    return h + ':' + m;
  }

  static calculaFecha(fecha) {
    return fecha.split(' ')[0];
  }

  static formatMedioPago(medioPago) {
    switch (medioPago) {
      case 'IN':
        return 'Internet';
      case 'CC':
        return 'Tarjeta de credito';
      case 'CA':
        return 'Efectivo';
      case 'TR':
        return 'Transferencia';
      case 'TM':
        return 'Tarjeta Monedero';  //Yaribel 11112020 añadimos forma de pago Tarjeta Monedero       
      case 'DP':
        return 'Aplazado';
      case 'PR':
        return 'Prepago'; //Yaribel 20201217 añadimos forma de pago Prepago
      case 'SP':
        return 'SINPE';
      default:
        return '';
    }
  }
}
